import React from 'react'
import ProfileAvatar from '../profile/JKProfileAvatar'
import TimeAgo from '../common/JKTimeAgo';
import useUserProfile from '../../hooks/useUserProfile';
import { useSelector } from 'react-redux';

const JKGenericNotification = (notification) => {
  
  const {formatted_msg, created_at, source_user_id} = notification;

  const user = useSelector(state => state.people.people.find(person => person.id === source_user_id));
  const { photoUrl } = useUserProfile(user); // user is the person who sent the message

  return (
    <>
    <div className="notification-avatar mr-3">
      <ProfileAvatar src={photoUrl}  />
    </div> 
    <div className="notification-body">
      <p className="mb-1">{formatted_msg}</p>
      <span className="notification-time">
        <TimeAgo date={created_at} />
      </span>
    </div>
    </>
  )
}

export default JKGenericNotification
