import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import moment from 'moment';

import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import ScrollBarCustom from '../common/ScrollBarCustom';

import { useAuth } from '../../context/UserAuth';
import { useTranslation } from 'react-i18next';

import JKProfileAvatar from './JKProfileAvatar';
import JKProfileInstrumentsList from './JKProfileInstrumentsList';

import JKProfileOnlinePresence from './JKProfileOnlinePresence';
import JKProfileInterests from './JKProfileInterests';
import JKProfileGenres from './JKProfileGenres';
import JKProfilePerformanceSamples from './JKProfilePerformanceSamples';
import JKConnectButton from './JKConnectButton';
import JKMessageButton from './JKMessageButton';
import JKLatencyBadge from './JKLatencyBadge';
import JKLastActiveAgo from './JKLastActiveAgo';
import JKProfileSkillLevel from './JKProfileSkillLevel';

const JKProfileSidePanel = props => {
  const { show, setShow, user, latencyData } = props;
  const { currentUser } = useAuth();
  const toggle = () => setShow(!show);
  const { t } = useTranslation();

  const joinedDate = () => {
    try {
      return moment(new Date(parseInt(user.created_at_timestamp) * 1000)).format('MM-DD-YYYY');
    } catch (e) {
      return 'Not specified';
    }
  };

  const location = () => {
    if (user.location && user.location.trim().length && user.country && user.country.trim().length) {
      return `${user.location}, ${user.country}`;
    } else if (user.country && user.country.trim().length) {
      return user.country;
    } else {
      return 'Not specified';
    }
  };

  return (
    <Modal
      isOpen={show}
      toggle={toggle}
      modalClassName="overflow-hidden modal-profile modal-fixed-right w-100 modal-theme"
      className="modal-dialog-vertical"
      contentClassName="vh-100 border-0"
      data-testid="profileSidePanel"
    >
      <ModalHeader tag="div" toggle={toggle} className="modal-header-settings">
        {user && (
          <Fragment>
            <div className="avatar avatar-2xl d-inline-block me-2 mr-2">
              <JKProfileAvatar src={user.photo_url} size="2xl" />
            </div>
            <h4 className="d-inline-block align-middle mt-n3 pt-0">{user.name}</h4>
          </Fragment>
        )}
      </ModalHeader>
      <ScrollBarCustom
        className="modal-body"
        contentProps={{
          renderer: ({ elementRef, ...restProps }) => (
            <span {...restProps} ref={elementRef} className={classNames('p-card position-absolute')} />
          )
        }}
      >
        <ModalBody className="pb-5">
          {user && (
            <div>
              <div className="mb-3">
                <strong>{t('person_attributes.latency_to_me', { ns: 'people' })}:</strong>{' '}
                <JKLatencyBadge latencyData={latencyData} showAll={true} />
                <br />
                <strong>{t('person_attributes.location', { ns: 'people' })}:</strong> {`${location()}`}
                <br />
                <strong>{t('person_attributes.skill_level', { ns: 'people' })}:</strong>{' '}
                <JKProfileSkillLevel skillLevel={user.skill_level} />
                <br />
                <strong>{t('person_attributes.joined_jamkazam', { ns: 'people' })}:</strong> {joinedDate()}
                <br />
                <strong>{t('person_attributes.last_active', { ns: 'people' })}:</strong>{' '}
                <JKLastActiveAgo timestamp={user.last_active_timestamp} />
              </div>

              {user.biography && user.biography.length > 0 && (
                <div data-testid="biography">
                  <h5>{t('person_attributes.about', { ns: 'people' })}</h5>
                  <p>{user.biography}</p>
                </div>
              )}

              {user.instruments && user.instruments.length && (
                <div data-testid="instruments">
                  <h5>{t('person_attributes.instruments', { ns: 'people' })}</h5>
                  <JKProfileInstrumentsList instruments={user.instruments} showAll={true} />
                </div>
              )}

              {user.genres && user.genres.length > 0 && (
                <div data-testid="genres">
                  <br />
                  <h5>{t('person_attributes.genres', { ns: 'people' })}</h5>
                  <JKProfileGenres genres={user.genres} showAll={true} />
                </div>
              )}

              {user.bands && user.bands.length > 0 && (
                <div data-testid="bands">
                  <br />
                  <h5>{t('person_attributes.bands', { ns: 'people' })}</h5>
                  {user.bands.map(band => (
                    <p key={band.id}>
                      <a href={band.website} target="_blank">
                        {band.name}
                      </a>
                    </p>
                  ))}
                </div>
              )}

              {user.performance_samples && user.performance_samples.length > 0 && (
                <div data-testid="performance_samples">
                  <h5>{t('person_attributes.performance_samples', { ns: 'people' })}</h5>
                  <JKProfilePerformanceSamples samples={user.performance_samples} />
                </div>
              )}

              {user.online_presences && user.online_presences.length > 0 && (
                <div data-testid="online_presences">
                  <br />
                  <h5>{t('person_attributes.online_presence', { ns: 'people' })}</h5>
                  <JKProfileOnlinePresence onlinePresences={user.online_presences} userId={user.id} />
                </div>
              )}

              <br />

              {user.genres && user.genres.length > 0 && (
                <>
                  <h5>{t('person_attributes.interests', { ns: 'people' })}</h5>
                  <JKProfileInterests user={user} />
                </>
              )}

              {currentUser && (
                <div className="p-3 bg-white border-top fixed-bottom">
                  <JKConnectButton
                    cssClasses="mb-2 mb-md-0"
                    currentUser={currentUser}
                    user={user}
                    addContent={
                      <>
                        <FontAwesomeIcon icon="plus" transform="shrink-4 down-1" className="mr-1" />{' '}
                        {t('add_friend', { ns: 'people' })}{' '}
                      </>
                    }
                    removeContent={
                      <>
                        <FontAwesomeIcon icon="minus" transform="shrink-4 down-1" className="mr-1" />{' '}
                        {t('disconnect', { ns: 'people' })}
                      </>
                    }
                  />{' '}
                  <JKMessageButton size="md" currentUser={currentUser} user={user}>
                    <FontAwesomeIcon icon="comments" transform="shrink-4 down-1" className="mr-1" />{' '}
                    {t('send_message', { ns: 'people' })}
                  </JKMessageButton>
                </div>
              )}
            </div>
          )}
        </ModalBody>
      </ScrollBarCustom>
    </Modal>
  );
};

JKProfileSidePanel.propTypes = {
  user_id: PropTypes.string
};

JKProfileSidePanel.defaultProps = {};

export default JKProfileSidePanel;
