import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const JKTooltip = ({ title, color, icon, size, placement }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const iconRef = useRef();
  const toggle = () => setTooltipOpen(!tooltipOpen);

  function handleClick(e) {
    e.preventDefault();
  }
  return (
    <a href="#!" onClick={handleClick} style={{ color: color}}>
      <span ref={iconRef}>
        <FontAwesomeIcon icon={icon} size={size} />
      </span>
      <Tooltip placement={placement} isOpen={tooltipOpen} target={iconRef} toggle={toggle}>
        {title}
      </Tooltip>
    </a>
  );
};

JKTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.string,
  placement: PropTypes.string
}

JKTooltip.defaultProps = {
  color: '#999',
  icon: 'question-circle',
  size: '1x',
  placement: 'auto'
}

export default JKTooltip;
