import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useResponsive } from '@farfetch/react-context-responsive';

const JKProfileGenres = ({ genres, showAll, toggleMoreDetails }) => {
  const LIMIT = 4;
  const [genresToShow, setGenresToShow] = useState([]);
  const { greaterThan } = useResponsive();
 

  useEffect(() => {
    if(genres){
      showAll || !greaterThan.xs ? setGenresToShow(genres) : setGenresToShow(genres.slice(0, LIMIT));
    }
  }, [showAll]);

  return (
    <div data-testid="genreList">
      { genresToShow && <div className="text-capitalize">
        { genresToShow.map(g => g.description ? g.description : g.genre_id).join(', ') }
        </div>
      }
      {
      ((!showAll && greaterThan.xs) || (showAll && !greaterThan.xs)) && genres.length > LIMIT && (
        <a href="#/" onClick={e => toggleMoreDetails(e)}>
          More »
        </a>
      )}
    </div>
  );
};

JKProfileGenres.propTypes = {
  genres: PropTypes.arrayOf(PropTypes.object).isRequired,
  showAll: PropTypes.bool,
  toggleMoreDetails: PropTypes.func
};

JKProfileGenres.defaltProps = {
  showAll: false
};

export default JKProfileGenres;
