import React from 'react';
import PropTypes from 'prop-types';

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

import { Card, CardBody, CardHeader } from 'reactstrap';

import JKPerson from './JKPerson';
import JKProfileAvatar from '../profile/JKProfileAvatar';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const JKPeopleSwiper = ({ people, goNextPage }) => {

  return (
    <>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        onSlideChange={() => console.log('slide change')}
        onSlideNextTransitionEnd={swiper => {
          if(swiper.isEnd){
            goNextPage()
          }
        }}
        pagination={{
          clickable: true,
          type: 'custom'
        }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }}
      >
        {people.map((person, index) => (
          <SwiperSlide key={`people-swiper-item-${person.id}`}>
            <Card className="swiper-card">
              <CardHeader className="bg-200">
                <div className="avatar avatar-xl d-inline-block me-2 mr-2">
                  <JKProfileAvatar url={person.photo_url} size="xl"/>
                </div>
                <h5 className="d-inline-block align-top mt-1">{person.name}</h5>
              </CardHeader>
              <CardBody>
                <JKPerson person={person} viewMode="swipe" />
              </CardBody>
            </Card>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="py-4 px-6 bg-white border-top w-100 fixed-bottom">
        <div className="swiper-pagination" />
        <div className="swiper-button-prev" />
        <div className="swiper-button-next" />
      </div>
    </>
  );
};

JKPeopleSwiper.propTypes = {
  people: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  goNextPage: PropTypes.func
};

export default JKPeopleSwiper;
