import React from 'react';
import PropTypes from 'prop-types';
import JKProfileAvatar from '../profile/JKProfileAvatar';
import { isIterableArray } from '../../helpers/utils';
import Loader from '../common/Loader';
import { useTranslation } from 'react-i18next';

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import JKLobbyUser from '../sessions/JKLobbyUser';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

import { Card, CardBody, CardHeader } from 'reactstrap';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const JKLobbyUserSwiper = ({ onlineMusicians, setSelectedUsers, loadingStatus }) => {
  const { t } = useTranslation('sessions');
  return (
    <>
      {loadingStatus === 'loading' && onlineMusicians.length === 0 ? (
        <Loader />
      ) : (
        <>
         {isIterableArray(onlineMusicians) ? (
        <>
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            //onSlideChange={() => console.log('slide change')}
            onSlideNextTransitionEnd={swiper => {
              if (swiper.isEnd) {
                //goNextPage()
              }
            }}
            pagination={{
              clickable: true,
              type: 'custom'
            }}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            }}
          >
            {onlineMusicians.map((musician, index) => (
              <SwiperSlide key={`session-lobby-swiper-item-${musician.id}`}>
                <Card className="swiper-card">
                  <CardHeader className="bg-200">
                    <div className="avatar avatar-xl d-inline-block me-2 mr-2">
                      <JKProfileAvatar url={musician.photo_url} size="xl" />
                    </div>
                    <h5 className="d-inline-block align-top mt-1">{musician.name}</h5>
                  </CardHeader>
                  <CardBody>
                    <JKLobbyUser user={musician} setSelectedUsers={setSelectedUsers} viewMode="swipe" />
                  </CardBody>
                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="py-4 px-6 bg-white border-top w-100 fixed-bottom">
            <div className="swiper-pagination" />
            <div className="swiper-button-prev" />
            <div className="swiper-button-next" />
          </div>
        </>
         ) : (
            <div className='mt-2 ml-2'>{t('lobby.users.not_found')}</div>
          )}
        </>
      )}
    </>
  );
};

JKLobbyUserSwiper.propTypes = {
  onlineMusicians: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  setSelectedUsers: PropTypes.func.isRequired
};

export default JKLobbyUserSwiper;
