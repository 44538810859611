import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionsHistory } from '../../helpers/rest'

const initialState = {
  sessions: [],
  status: 'idel',
  error: null,
}

export const fetchSessionsHistory = createAsyncThunk(
  "sessionsHistory/fetchHistory", 
  async (options, thunkAPI) => {
    const response = await getSessionsHistory(options);
    return response.json();
  }
)

export const SessionsHistorySlice = createSlice({
  name: "sessionsHistory",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSessionsHistory.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchSessionsHistory.fulfilled, (state, action) => {
        console.log(action.payload);
        const records = new Set([...state.sessions, ...action.payload]);
        const unique = [];
        records.map(x => unique.filter(a => a.session_history_id === x.session_history_id).length > 0 ? null : unique.push(x))
        state.sessions = unique
        state.status = 'succeeded'
      })
      .addCase(fetchSessionsHistory.rejected, (state, action) => {
        state.status = 'failed'
        state.error  = action.error.message
      })
  }
})

export default SessionsHistorySlice.reducer