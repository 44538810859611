import React, { useState, useEffect } from 'react';

import { Card, CardBody, Row, Col, Alert } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import { useTranslation } from 'react-i18next';
import { getAffiliatePayments } from '../../helpers/rest';
import { isIterableArray } from '../../helpers/utils';
import { useResponsive } from '@farfetch/react-context-responsive';
import JKAffiliateEarningsList from './JKAffiliateEarningsList';
import JKAffiliateEarningsSwiper from './JKAffiliateEarningsSwiper';

import Loader from '../common/Loader';

const JKAffiliateEarnings = () => {
  const { t } = useTranslation('affiliate');
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const { greaterThan } = useResponsive();

  useEffect(() => {
    setLoading(true);
    getAffiliatePayments()
      .then(resp => {
        return resp.json();
      })
      .then(data => {
        setPayments(data.payments);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Card style={{ width: greaterThan.sm ? '70%' : '100%' }} className="mx-auto affiliate-links">
      <FalconCardHeader title={t('earnings.page_title')} titleClass="font-weight-semi-bold" />
      <CardBody className="pt-3 pb-5">
        {loading ? (
          <Loader />
        ) : isIterableArray(payments) ? (
          <>
            {greaterThan.sm ? (
              <Row className="mb-3 justify-content-between d-none d-md-block">
                <div className="table-responsive-xl px-2">
                  <JKAffiliateEarningsList payments={payments} />
                </div>
              </Row>
            ) : (
              <Row className="swiper-container d-block d-md-none" data-testid="sessionsSwiper">
                <JKAffiliateEarningsSwiper payments={payments} />
              </Row>
            )}
          </>
        ) : (
          <Row className="p-card">
            <Col>
              <Alert color="info" className="mb-0">
                {t('no_records', { ns: 'common' })}
              </Alert>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default JKAffiliateEarnings;
