import React from 'react';
import { Link } from 'react-router-dom';
import { titleize } from '../../helpers/utils';

const JKProfileOnlinePresence = ({ onlinePresences, userId }) => {
  const itemList = [];

  const serviceUrl = item => {
    let url;
    switch (item.service_type) {
      case 'soundcloud':
        url = `https://www.soundcloud.com/${item.username}`;
        break;

      case 'reverbnation':
        url = `https://www.reverbnation.com/${item.username}`;
        break;

      case 'bandcamp':
        url = `https://${item.username}.bandcamp.com`;
        break;

      case 'fandalism':
        url = `https://www.fandalism.com/${item.username}`;
        break;

      case 'youtube':
        url = `https://www.youtube.com/${item.username}`;
        break;

      case 'facebook':
        url = `https://www.facebook.com/${item.username}`;
        break;

      case 'twitter':
        url = `https://www.twitter.com/${item.username}`;
        break;
    }
    return url;
  };

  

  return (
    <>
      {onlinePresences.map(item => (
        <Link to={{pathname: serviceUrl(item)}} target="_blank" key={`${item.service_type}-${userId}`}>
          {titleize(item.service_type)}
        </Link>
      )).reduce((prev, curr) => [prev, ', ', curr])
      }
      
    </>
  );
};

export default JKProfileOnlinePresence;
