import React from 'react';
import { useResponsive } from '@farfetch/react-context-responsive';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const JKPayment = ({ payment }) => {
  const { greaterThan } = useResponsive();
  const { t } = useTranslation('account');
  return (
    <>
      {greaterThan.sm ? (
        <tr key={payment.id}>
          <td>
            {new Date(payment.created_at).toLocaleDateString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            })}
          </td>
          <td>{payment.description}</td>
          <td className='text-capitalize'>{payment.status}</td>
          <td>{`$${(payment.total_in_cents / 100).toFixed(2)}`}</td>
        </tr>
      ) : (
        <>
          <small>
            <Row>
              <Col xs={4} className="mb-2 text-right">
                {t('payments.payment_attributes.date')}:
              </Col>
              <Col xs={8} className="mb-2 pl-2">
                {new Date(payment.created_at).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit'
                })}
              </Col>
            </Row>
            <Row>
              <Col xs={4} className="mb-2 text-right">
                {t('payments.payment_attributes.description')}:
              </Col>
              <Col xs={8} className="mb-2 pl-2">
                {payment.description}
              </Col>
            </Row>
            <Row>
              <Col xs={4} className="mb-2 text-right">{t('payments.payment_attributes.status')}:</Col>
              <Col xs={6} className="mb-2 pl-2 text-capitalize">
                {payment.status}
              </Col>
            </Row>
            <Row>
              <Col xs={4} className="mb-2 text-right">{t('payments.payment_attributes.amount')}:</Col>
              <Col xs={8} className="mb-2 pl-2">
                {`$${(payment.total_in_cents / 100).toFixed(2)}`}
              </Col>
            </Row>
          </small>
        </>
      )}
    </>
  );
};

export default JKPayment;
