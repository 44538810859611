import React from 'react';
import { groupByKey } from '../../helpers/utils';
import { Table } from 'reactstrap';
import JKSessionsHistoryItem from './JKSessionsHistoryItem';
import { useTranslation } from 'react-i18next';

const JKSessionsHistoryList = ({ sessions }) => {
  const sessionsById = groupByKey(sessions, 'session_id');
  const { t } = useTranslation();

  return (
    <>
      <Table striped bordered className="fs--1">
        <thead className="bg-200 text-900">
          <tr>
            <th width="35%" scope="col">
              {t('list.header.session', { ns: 'sessions' })}
            </th>
            <th width="15%" scope="col" style={{ minWidth: 250 }}>
              {t('list.header.musicians', { ns: 'sessions' })}
            </th>
            <th scope="col" className="text-center">
              {t('list.header.latency', { ns: 'sessions' })}
            </th>
            <th scope="col">{t('list.header.instruments', { ns: 'sessions' })}</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(sessionsById).map(([session_id, sessionGroup]) => (
            <JKSessionsHistoryItem key={session_id} session_id={session_id} sessionGroup={sessionGroup} />
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default JKSessionsHistoryList;
