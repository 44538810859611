import React from 'react'

function JKProfileSkillLevel({skillLevel}) {
  const SKILL_LEVEL_MAP = {
    "1": "Amateur",
    "2": "Professional"
  };
  return (
    <span>
      {
        skillLevel in SKILL_LEVEL_MAP ? SKILL_LEVEL_MAP[skillLevel] : 'Not specified'
      }
    </span>
  )
}

export default JKProfileSkillLevel
