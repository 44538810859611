import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon
} from 'reactstrap';

import { useTranslation } from 'react-i18next';
import { useAuth } from '../../context/UserAuth';
import { useForm, Controller } from 'react-hook-form';
import { postUpdateAccountEmail } from '../../helpers/rest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const JKEditEmail = ({setAlert, toggleAlert}) => {
  const { t } = useTranslation('account');
  const { currentUser } = useAuth();
  const [user, setUser] = useState(null);
  const [showEmailPassword, setShowEmailPassword] = useState(false);
  const [submitting, setSubmitting] = useState(false)

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
  } = useForm({
    defaultValues: {
      current_password: '',
      new_email: ''
    }
  });

  useEffect(() => {
    if (currentUser) {
      setUser(currentUser);
    }
  }, [currentUser]);

  const onSubmitEmail = (data) => {
    setSubmitting(true)
    //post
    const { new_email, current_password } = data;
    postUpdateAccountEmail(currentUser.id, { email: new_email, current_password })
      .then(response => {
        setAlert(t('identity.email_form.alerts.confirmation_email_sent'));
        setValue('current_password', '');
        setValue('new_email', '');
        toggleAlert()
      })
      .catch(async error => {
        const errorResp = await error.json()
        console.log(errorResp)
        if(errorResp.errors){
          const errors = errorResp.errors;
          if(errors.current_password && errors.current_password.length){
            errors.current_password.forEach(error => {
              setError('current_password', {
                type: 'manual',
                message: `${t('identity.email_form.current_password')} ${error}`
              })
            })
          }
          if(errors.update_email && errors.update_email.length){
            errors.update_email.forEach(error => {
              setError('new_email', {
                type: 'manual',
                message: `${t('identity.email_form.new_email')} ${error}`
              })
            })
          }
        }
       
      }).finally(() => {
        setSubmitting(false)
      })
  };

  
  return (
    <Card>
      <CardHeader>
        <h5>{t('identity.email_form.title')}</h5>
      </CardHeader>
      <CardBody className="bg-light" style={{ minHeight: 300 }}>
        <small>
          {t('identity.email_form.help_text')}
        </small>

        <Form noValidate className="mt-2" onSubmit={handleSubmit(onSubmitEmail)} data-testid="edit_email_form">
          <FormGroup>
            <Label for="current_password">{t('identity.email_form.current_password')}</Label>
            <Controller
              name="current_password"
              control={control}
              rules={{ required: t('identity.email_form.validations.current_password.required') }}
              render={({ field }) => (
                <InputGroup>
                  <Input
                    {...field}
                    type={showEmailPassword ? 'text' : 'password'}
                    className="form-control"
                    id="current_password"
                    placeholder={t('identity.email_form.current_password')}
                  />
                  <InputGroupAddon
                    addonType="append"
                    onClick={() => {
                      setShowEmailPassword(!showEmailPassword);
                    }}
                  >
                    <span className="input-group-text">
                      <FontAwesomeIcon icon={showEmailPassword ? 'eye-slash' : 'eye'} />
                    </span>
                  </InputGroupAddon>
                </InputGroup>
              )}
            />
            {errors.current_password && <div className="text-danger"><small>{errors.current_password.message}</small></div>}
          </FormGroup>
          <FormGroup>
            <Label for="new_email">{t('identity.email_form.new_email')}</Label>
            <Controller
              name="new_email"
              control={control}
              rules={{
                required: t('identity.email_form.validations.new_email.required'),
                pattern: {
                  value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                  message: t('identity.email_form.validations.new_email.pattern')
                }
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  type="email"
                  className="form-control"
                  id="new_email"
                  placeholder={user ? user.email : t('identity.email_form.new_email')}
                />
              )}
            />
            {errors.new_email && <div className="text-danger"><small>{errors.new_email.message}</small></div>}
          </FormGroup>
          <div className='d-flex align-content-center justify-content-start'>
            <input type="submit" formNoValidate className="btn btn-primary" value={t('identity.email_form.submit')} disabled={submitting} data-testid="email_submit" />
            <span className='ml-2'>
              { submitting && <FontAwesomeIcon icon="spinner" />}
            </span>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default JKEditEmail;
