import React from 'react';
import { titleize } from '../../helpers/utils';

const JKProfilePerformanceSamples = ({ samples }) => {
  const SAMPLE_TYPES = {
    JAMKAZAM: { description: 'jamkazam' },
    SOUNDCLOUD: { description: 'soundcloud' },
    YOUTUBE: { description: 'youtube' }
  };

  const jamkazamSamples = samples.filter(
    sample => sample.claimed_recording && sample.service_type === SAMPLE_TYPES.JAMKAZAM.description
  );

  const soundCloudSamples = samples.filter(
    sample => sample.claimed_recording && sample.service_type === SAMPLE_TYPES.SOUNDCLOUD.description
  );

  const youTubeSamples = samples.filter(
    sample => sample.claimed_recording && sample.service_type === SAMPLE_TYPES.YOUTUBE.description
  );

  return (
    <>
      {jamkazamSamples.length > 0 && (
        <div className='mb-2'>
          <strong>JamKazam: </strong>
          {jamkazamSamples.map(sample => (
            <a className='mr-2' href={`/recordings/${sample.claimed_recording.id}`}>{titleize(sample.claimed_recording.name)}</a>
          ))}
        </div>
      )}

      {soundCloudSamples.length > 0 && (
        <div className='mb-2'>
          <strong>SoundCloud: </strong>
          {soundCloudSamples.map(sample => (
            <a className='mr-2' href={`${sample.claimed_recording.id}`}>{titleize(sample.claimed_recording.name)}</a>
          ))}
        </div>
      )}
     
      {youTubeSamples.length > 0 && (
        <div>
          <strong>YouTube: </strong>
          {youTubeSamples.map(sample => (
            <a className='mr-2' href={`${sample.claimed_recording.id}`}>{titleize(sample.claimed_recording.name)}</a>
          ))}
        </div>
      )}
    </>
  );
};

export default JKProfilePerformanceSamples;
