import customProtocolCheck from 'custom-protocol-check';

const useNativeAppCheck = () => {
  const isAvailable = () => {
    const customUrlScheme = 'jamkazam://';
    return new Promise((resolve, reject) => {
      customProtocolCheck(
        customUrlScheme,
        () => {
          //Custom protocol NOT found
          reject('JamKazam application cannot be found.');
        },
        () => {
          //Custom protocol found and opened the file successfully
          resolve();
        },
        1000
      );
    });
  };
  return isAvailable;
};

export default useNativeAppCheck;
