import React, { useRef, useState, useEffect } from 'react';
//import {useHistory} from 'react-router-dom';
import { Form, FormGroup, Input, Label, Card, CardBody, Button, Row, Col } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import JKTooltip from '../common/JKTooltip';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../context/UserAuth';
import JKFriendsAutoComplete from '../people/JKFriendsAutoComplete';
import JKSessionInviteesChips from '../people/JKSessionInviteesChips';
import { getFriends } from '../../helpers/rest';
import jkCustomUrlScheme from '../../helpers/jkCustomUrlScheme';
import JKModalDialog from '../common/JKModalDialog';
import useNativeAppCheck from '../../hooks/useNativeAppCheck';
import { useNativeApp } from '../../context/NativeAppContext';
import { useResponsive } from '@farfetch/react-context-responsive';
import { sessionPrivacyMap } from '../../config';
// const privacyMap = {
//   public: 1,
//   private_invite: 2,
//   private_approve: 3
// };


const JKNewMusicSession = () => {
  const { currentUser } = useAuth();
  const { t } = useTranslation();
  const [friends, setFriends] = useState([]);
  const [isFriendsFetched, setIsFriendsFetched] = useState(false);
  const [description, setDescription] = useState('');
  const [invitees, setInvitees] = useState([]);
  const [privacy, setPrivacy] = useState('1');
  const [submitted, setSubmitted] = useState(false);
  const [showAppUnavailable, setShowAppUnavailable] = useState(false);
  //const history = useHistory();
  const formRef = useRef();
  const isNativeAppAvailable = useNativeAppCheck();
  const { nativeAppUnavailable, setNativeAppUnavailable } = useNativeApp();
  const { greaterThan } = useResponsive();

  useEffect(() => {
    fetchFriends();
  }, []);

  useEffect(() => {
    if (isFriendsFetched) {
      populateFormDataFromLocalStorage();
    }
  }, [isFriendsFetched]);

  const fetchFriends = () => {
    getFriends(currentUser.id)
      .then(resp => {
        if (resp.ok) {
          return resp.json();
        }
      })
      .then(data => {
        setFriends(data);
        setIsFriendsFetched(true);
      });
  };

  const populateFormDataFromLocalStorage = () => {
    try {
      const formData = localStorage.getItem('formData');
      if (formData) {
        const formDataItems = JSON.parse(formData);
        setDescription(formDataItems['description']);
        setPrivacy(formDataItems['privacy']);
        const inviteeIds = formDataItems['inviteeIds'];
        const invitees = friends.filter(f => inviteeIds.includes(f.id));
        updateSessionInvitations(invitees);
      }
    } catch (error) {
      console.error('localStorage is not available', error);
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setSubmitted(true);
    const formData = new FormData(event.target);
    const payload = {
      privacy: formData.get('privacy'),
      description: formData.get('description'),
      inviteeIds: invitees.map(i => i.id).join()
    };
    try {
      //store this payload in localstorage.
      localStorage.setItem('formData', JSON.stringify(payload));
    } catch (error) {
      console.error('localStorage is not available', error);
    }
    //check if jamkazam app is installed
    try {
      await isNativeAppAvailable();
      //window.open jamkazam app url using custom URL scheme
      //an example URL would be: jamkazam://url=https://www.jamkazam.com/client#/createSession/privacy~2|description~hello|inviteeIds~1,2,3,4
      const q = `privacy~${payload.privacy}|description~${payload.description}|inviteeIds~${payload.inviteeIds}`;
      const urlScheme = jkCustomUrlScheme('createSession', q);
      window.open(urlScheme);
      //history.push('/sessions');
    } catch (error) {
      toggleAppUnavilableModel();
    }
    return false;
  };

  const handleOnSelect = submittedItems => {
    updateSessionInvitations(submittedItems);
  };

  const updateSessionInvitations = submittedInvitees => {
    const updatedInvitees = Array.from(new Set([...invitees, ...submittedInvitees]));
    setInvitees(updatedInvitees);

    const friendIds = submittedInvitees.map(si => si.id);
    const updatedFriends = friends.filter(f => !friendIds.includes(f.id));
    setFriends(updatedFriends);
  };

  const removeInvitee = invitee => {
    const updatedInvitees = invitees.filter(i => i.id !== invitee.id);
    setInvitees(updatedInvitees);
    const updatedFriends = [...friends, invitee];
    setFriends(updatedFriends);
  };

  const toggleAppUnavilableModel = () => {
    setNativeAppUnavailable(prev => !prev);
    if (!nativeAppUnavailable) {
      setSubmitted(false);
    }
  };

  return (
    <div>
      <Card>
        <FalconCardHeader title={t('new.page_title', { ns: 'sessions' })} titleClass="font-weight-bold" />
        <CardBody className="pt-0">
          <Row>
            <Col>
              <Form onSubmit={handleSubmit} ref={formRef}>
                <FormGroup className="mb-3">
                  <Label>
                    {t('new.privacy', { ns: 'sessions' })}{' '}
                    <JKTooltip title={t('new.privacy_help', { ns: 'sessions' })} />
                  </Label>
                  <Input
                    type="select"
                    aria-label="Session Privacy"
                    name="privacy"
                    value={privacy}
                    onChange={e => setPrivacy(e.target.value)}
                    data-testid="session-privacy"
                  >
                    <option value={sessionPrivacyMap['public']}>{t('new.privacy_opt_public', { ns: 'sessions' })}</option>
                    <option value={sessionPrivacyMap['private_invite']}>
                      {t('new.privacy_opt_private_invite', { ns: 'sessions' })}
                    </option>
                    <option value={sessionPrivacyMap['private_approve']}>
                      {t('new.privacy_opt_private_approve', { ns: 'sessions' })}
                    </option>
                  </Input>
                </FormGroup>

                <FormGroup className="mb-3">
                  <Label>
                    {t('new.invitations', { ns: 'sessions' })}{' '}
                    <JKTooltip title={t('new.invitations_help', { ns: 'sessions' })} />
                  </Label>
                  <JKFriendsAutoComplete friends={friends} onSelect={handleOnSelect} />
                  {invitees.length > 0 && <JKSessionInviteesChips invitees={invitees} removeInvitee={removeInvitee} />}
                </FormGroup>
                <FormGroup className="mb-3">
                  <Label>
                    {t('new.description', { ns: 'sessions' })}{' '}
                    <JKTooltip title={t('new.description_help', { ns: 'sessions' })} />
                  </Label>
                  <Input
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    name="description"
                    type="textarea"
                    data-testid="session-description"
                    placeholder={t('new.description_placeholder', { ns: 'sessions' })}
                  />
                </FormGroup>

                <FormGroup className="mb-3">
                  <Button color="primary" data-testid="btn-create-session" disabled={submitted}>
                    {t('new.create_session', { ns: 'sessions' })}
                  </Button>
                </FormGroup>
              </Form>
            </Col>
            { greaterThan.lg && (
               <Col />
            )}
           
          </Row>
        </CardBody>
      </Card>
      <JKModalDialog
        show={nativeAppUnavailable}
        onToggle={toggleAppUnavilableModel}
        title={t('modals.native_app_unavailable.title', { ns: 'common' })}
      >
        <p>{t('modals.native_app_unavailable.body', { ns: 'common' })}</p>
        <div className="d-flex flex-row">
          <a
            href="https://www.jamkazam.com/downloads"
            onClick={() => toggleAppUnavilableModel()}
            target="_blank"
            className="btn btn-primary mr-2"
          >
            {t('modals.native_app_unavailable.download_button', { ns: 'common' })}
          </a>
          <a
            href="https://www.jamkazam.com/help_desk"
            onClick={() => toggleAppUnavilableModel()}
            target="_blank"
            className="btn btn-light"
          >
            {t('modals.native_app_unavailable.help_button', { ns: 'common' })}
          </a>
        </div>
      </JKModalDialog>
    </div>
  );
};

export default JKNewMusicSession;
