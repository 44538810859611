import React, { createContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchLobbyChatMessages } from '../../store/features/lobbyChatMessagesSlice';
import useKeepScrollPosition from '../../hooks/useKeepScrollPosition';
// Create the context
export const JKLobbyChatContext = createContext();

// Create a provider component
export const JKLobbyChatProvider = ({ children }) => {
  const dispatch = useDispatch();
  const LIMIT = 10;
  const [messages, setMessages] = useState([]);
  const [lobbyChatLimit, setLobbyChatLimit] = useState(LIMIT);
  const [lobbyChatOffset, setLobbyChatOffset] = useState(0);
  const [lobbyChatFetching, setLobbyChatFetching] = useState(false);
  const { containerRef } = useKeepScrollPosition([messages]);

  const fetchLobbyMessages = async (overrides = {}) => {
    const options = { start: lobbyChatOffset * LIMIT, limit: LIMIT };
    const params = { ...options, ...overrides };
    try {
      setLobbyChatFetching(true);
      await dispatch(fetchLobbyChatMessages(params)).unwrap();
    } catch (error) {
      console.log('Error when fetching chat messages', error);
    } finally {
      setLobbyChatFetching(false);
    }
  };

  const goToBottom = () => {
    containerRef.current.scrollTop = containerRef.current.scrollHeight;
  };

  return (
    <JKLobbyChatContext.Provider value={{ messages, setMessages, lobbyChatOffset, setLobbyChatOffset, fetchLobbyMessages, lobbyChatLimit, goToBottom, containerRef }}>
      {children}
    </JKLobbyChatContext.Provider>
  );
};

export const useLobbyChat = () => React.useContext(JKLobbyChatContext);