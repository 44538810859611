import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { deleteMixdown, getJamTrack, createMixdown } from '../../helpers/rest';

const initialState = {
  jamTrack: {},
  jamTrackLoadingStatus: 'idle',
  mixdownsLoadingStatus: 'idle',
  deleteMixdownStatus: 'idle',
  newMixdownLoadingStatus: 'idle',
  tempMixdownLoadingStatus: 'idle',

  error: null
}

export const fetchJamTrack = createAsyncThunk('jamTracks/fetchJamTrack', async(options, thunkAPI) => {
  const response = await getJamTrack(options)
  return response.json();
});

export const createMyMixdown = createAsyncThunk('jamTracks/createMixdown', async(options, thunkAPI) => {
  const response = await createMixdown(options)
  return response.json();
});

export const removeMixdown = createAsyncThunk('jamTracks/removeMixdown', async(options, thunkAPI) => {
  console.log('removeMixdown', options);
  const { id } = options;
  const response = await deleteMixdown(id)
  return { id };
});

export const jamTrackSlice = createSlice({
  name: 'jamTrack',
  initialState,
  reducers: {
    addMixdown: (state, action) => {
      const payload = action.payload;
      const jamTrack = state.jamTrack;
      if (jamTrack) {
        state.jamTrack.mixdowns = [...jamTrack.mixdowns, payload];
        state.tempMixdownLoadingStatus = 'succeeded';
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchJamTrack.pending, (state, action) => {
        state.jamTrackLoadingStatus = 'loading'
        state.mixdownsLoadingStatus = 'loading'
      })
      .addCase(fetchJamTrack.fulfilled, (state, action) => {
        state.jamTrack = action.payload
        state.jamTrackLoadingStatus = 'succeeded'

        if (action.payload.mixdowns) {
          state.mixdownsLoadingStatus = 'succeeded'
        }
      })
      .addCase(fetchJamTrack.rejected, (state, action) => {
        state.status = 'failed'
        state.jamTrackLoadingStatus = 'failed'
        state.mixdownsLoadingStatus = 'failed'
        state.error = action.error.message;
      })
      .addCase(createMyMixdown.pending, (state, action) => {
        state.newMixdownLoadingStatus = 'loading'
        state.mixdownsLoadingStatus = 'loading'
      })
      .addCase(createMyMixdown.fulfilled, (state, action) => {
        state.jamTrack.mixdowns = [...state.jamTrack.mixdowns, action.payload];
        state.newMixdownLoadingStatus = 'succeeded'
        state.mixdownsLoadingStatus = 'succeeded'
        state.tempMixdownLoadingStatus = 'idle'
      })
      .addCase(createMyMixdown.rejected, (state, action) => {
        state.error = action.error.message;
        state.newMixdownLoadingStatus = 'failed'
        state.tempMixdownLoadingStatus = 'idle'
      })
      .addCase(removeMixdown.pending, (state, action) => {
        state.mixdownsLoadingStatus = 'loading'
        state.deleteMixdownStatus = 'loading'
      })
      .addCase(removeMixdown.fulfilled, (state, action) => {
        console.log('mixdown removed', action.payload)
        const mixdowns = state.jamTrack.mixdowns.filter(mix => mix.id !== action.payload.id);
        state.jamTrack.mixdowns = mixdowns;
        state.mixdowns = mixdowns;
        state.mixdownsLoadingStatus = 'succeeded'
        state.deleteMixdownStatus = 'succeeded'
      })
      .addCase(removeMixdown.rejected, (state, action) => {
        state.error = action.error.message;
        state.mixdownsLoadingStatus = 'failed'
        state.deleteMixdownStatus = 'failed'
      })
  }
});

export const { addMixdown } = jamTrackSlice.actions;
export default jamTrackSlice.reducer;