import React from 'react';
import TimeAgo from 'react-timeago';

function JKTimeAgo(props) {
  return (
    <TimeAgo
      date={props.date}
      formatter={(value, unit) => {
        if (unit === 'second' && value < 15) return 'just now';
        if (unit === 'second') return 'few seconds ago';
        if (unit === 'minute') return `${value} ${value === 1 ? 'minute' : 'minutes'} ago`;
        if (unit === 'hour') return `${value} ${value === 1 ? 'hour' : 'hours'} ago`;
        if (unit === 'day') return `${value} ${value === 1 ? 'day' : 'days'} ago`;
        if (unit === 'week') return `${value} ${value === 1 ? 'week' : 'weeks'} ago`;
        if (unit === 'month') return `${value} ${value === 1 ? 'month' : 'months'} ago`;
        if (unit === 'year') return `${value} ${value === 1 ? 'year' : 'years'} ago`;
      }}
    />
  );
}

export default JKTimeAgo;
