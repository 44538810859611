import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types' 

function JKSessionInviteesChips({ invitees, removeInvitee }) {
  const remove = (event, invitee) => {
    event.preventDefault();
    removeInvitee(invitee);
  }
  return (
    <div className="chip-container" data-testid="selected-invitees">
      {invitees &&
        invitees.map(i => (
          <div key={i.id} className="basic-chip background-white">
            { i.first_name} {i.last_name }
            <a onClick={(e) => remove(e, i)}>
              <FontAwesomeIcon icon={faTimesCircle} />
            </a>
          </div>
        ))}
    </div>
  );
}

JKSessionInviteesChips.propTypes = {
  invitees: PropTypes.arrayOf(
    PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }).isRequired
  ),
  removeInvitee: PropTypes.func.isRequired,
}

export default JKSessionInviteesChips;
