import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, ListGroup, ListGroupItem, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import JKProfileAvatar from '../profile/JKProfileAvatar';

function JKSelectFriendsModal({ show, friends, onSubmit, toggleVisibility }) {
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState([])
  const { t } = useTranslation();

  const toggle = () => { 
    toggleVisibility(!modal) 
    setModal(!modal);
  }

  useEffect(() => {
    setModal(show);
  }, [show]);

  const onCheckBoxClick = (event) => {
    const friend = friends.find(f => f.id === event.target.value)
    if(event.target.checked){
      const updated = Array.from(new Set([...selected, friend]))
      setSelected(updated)
    }else{
      const updated = selected.filter(s => s.id !== friend.id)
      setSelected(updated)
    }   
  }

  const onButtonClick = () => {
    onSubmit(selected)
    setSelected([])
    toggle()
  }

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} scrollable={true} data-testid="modal-choose-friends">
        <ModalHeader toggle={toggle}>Invite Friends to Session</ModalHeader>
        <ModalBody>
          <ListGroup flush>
            {friends.map(f => (
              <ListGroupItem key={f.id} className="d-flex align-items-top">
                <Input type="checkbox" onClick={onCheckBoxClick} value={f.id} />
                <JKProfileAvatar src={f.photo_url} size="m" />
                <span className='ml-2'>
                  {f.first_name} {f.last_name}
                </span>
              </ListGroupItem>
            ))}
          </ListGroup>
        </ModalBody>
        <ModalFooter>
        <Button color="secondary" outline onClick={toggle}>
            {t('new.cancel', { ns: 'sessions' })}
          </Button>{' '}
          <Button color="primary" onClick={onButtonClick}>
          {t('new.add_friends', { ns: 'sessions' })}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

JKSelectFriendsModal.propTypes = {
  show: PropTypes.bool, 
  friends: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  })), 
  onSubmit: PropTypes.func.isRequired, 
  toggleVisibility: PropTypes.func.isRequired,
}

export default JKSelectFriendsModal;
