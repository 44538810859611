import apiFetch from "../helpers/apiFetch";

export const checkIsAuthenticated = () => {
  return new Promise((resolve, reject) => {
    apiFetch('/me')
    .then(response => resolve(response))
    .catch(error => reject(error))
  })
}

export const authSignUp = () => {}

export const authLogin = (credentials) => {
  return new Promise((resolve, reject) => {
    apiFetch('/auths/login', {
      method: 'POST',
      body: JSON.stringify(credentials)
    })
    .then(response => resolve(response))
    .catch(error => reject(error))
  })
}

export const authLogout = () => {
  return new Promise((resolve, reject) => {
    apiFetch('/auths/logout', {
      method: 'DELETE'
    })
    .then((response) => resolve(response))
    .catch((error) => reject(error))
  })
}