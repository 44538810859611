import React, { useState, useEffect } from 'react';
import { Table } from 'reactstrap';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { removeMixdown } from '../../store/features/jamTrackSlice';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const JKMyJamTrackMixes = () => {
  const [mixes, setMixes] = useState([]);
  const fpPromise = FingerprintJS.load();
  const dispatch = useDispatch();

  const jamTrack = useSelector(state => state.jamTrack.jamTrack);
  const mixdownsLoadingStatus = useSelector(state => state.jamTrack.mixdownsLoadingStatus);
  const deleteMixdownStatus = useSelector(state => state.jamTrack.deleteMixdownStatus);
  const tempMixdownLoadingStatus = useSelector(state => state.jamTrack.tempMixdownLoadingStatus);

  useEffect(() => {
    if (!jamTrack) {
      return;
    }
    if (mixdownsLoadingStatus === 'succeeded') {
      setMixes(jamTrack.mixdowns.filter(m => m.id !== 'temp'));
    }
  }, [mixdownsLoadingStatus]);

  useEffect(() => {
    if (tempMixdownLoadingStatus === 'succeeded') {
      setMixes(jamTrack.mixdowns);
    }
  }, [tempMixdownLoadingStatus]);

  const downloadJamTrack = async () => {
    console.log('Downloading JamTrack');
    if (!jamTrack.can_download) {
      console.log('Cannot download JamTrack');
      return;
    }
    const fp = await fpPromise;
    const result = await fp.get();
    const src = `${process.env.REACT_APP_API_BASE_URL}/jamtracks/${
      jamTrack.id
    }/stems/master/download.mp3?file_type=mp3&download=1&mark=${result.visitorId}`;
    openDownload(src);
  };

  const downloadMix = async mixId => {
    console.log('Download mixdown');
    const mixdown = mixes.find(m => m.id === mixId);
    const mixdownPackage = mixdown.packages.find(p => p.file_type === 'mp3');
    if (mixdownPackage?.signing_state == 'SIGNED') {
      const fp = await fpPromise;
      const result = await fp.get();
      const src = `${process.env.REACT_APP_API_BASE_URL}/mixdowns/${
        mixdown.id
      }/download.mp3?file_type=mp3&sample_rate=48&download=1&mark=${result.visitorId}`;
      openDownload(src);
    }else{
      console.log('Mixdown not signed');
    }
  };

  const openDownload = async src => {
    const iframe = document.createElement('iframe');
    iframe.src = src;
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
  };

  const deleteMix = mixId => {
    if (window.confirm('Delete this custom mix?')) {
      console.log('Deleting mixdown', mixId);
      dispatch(removeMixdown({ id: mixId }));
    }
  };

  return (
    <>
      <p>
        You can save a <strong>maximum of 5 mixes</strong> on JamKazam. If you need to make more mixes, download a mix
        to save it, then delete it to make more room
      </p>
      <Table striped bordered className="fs--1">
        <thead className="bg-200 text-900">
          <tr>
            <th>Mix</th>
            <th class="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Full JamTrack</td>
            <td class="text-center">
              <a onClick={downloadJamTrack}>
                <FontAwesomeIcon icon="download" size="lg" className="mr-3" />
              </a>
            </td>
          </tr>
          {mixes.map(mix => (
            <tr key={mix.id}>
              <td>{mix.name}</td>
              <td class="text-center">
                {mix.id === 'temp' ? (
                  <FontAwesomeIcon icon="spinner" size="lg" />
                ) : (
                  <>
                    <a onClick={() => downloadMix(mix.id)} style={{ cursor: 'pointer' }}>
                      <FontAwesomeIcon icon="download" size="lg" className="mr-3" />
                    </a>
                    <a
                      onClick={() => deleteMix(mix.id)}
                      disabled={deleteMixdownStatus === 'loading'}
                      style={{ cursor: 'pointer' }}
                    >
                      <FontAwesomeIcon icon="trash" size="xl" />
                    </a>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default JKMyJamTrackMixes;
