import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { titleize, currencyFormat } from '../../helpers/utils';

const JKProfileInterests = ({ user }) => {
  const [freeSessionGenres, setFreeSessionGenres] = useState([]);
  const [paidSessionGenres, setPaidSessionGenres] = useState([]);
  const [traditionalBandSessionGenres, setTraditionalBandSessionGenres] = useState([]);
  const [virtualBandSessionGenres, setVirtualBandSessionGenres] = useState([]);
  const [coWritingSessionGenres, setCoWritingSessionGenres] = useState([]);

  const { genres } = user;

  const USER_TYPE = 'JamRuby::User';

  // genre types
  var PROFILE_GENRE_TYPE = 'profile';
  var VIRTUAL_BAND_GENRE_TYPE = 'virtual_band';
  var TRADITIONAL_BAND_GENRE_TYPE = 'traditional_band';
  var PAID_SESSION_GENRE_TYPE = 'paid_sessions';
  var FREE_SESSION_GENRE_TYPE = 'free_sessions';
  var COWRITING_GENRE_TYPE = 'cowriting';

  useEffect(() => {
    if (user.paid_sessions) {
      const filteredGenres = genres.filter(genre => {
        return genre.player_type === USER_TYPE && genre.genre_type === PAID_SESSION_GENRE_TYPE;
      });
      setPaidSessionGenres(filteredGenres);
    }

    if (user.free_sessions) {
      const filteredGenres = genres.filter(genre => {
        return genre.player_type === USER_TYPE && genre.genre_type === FREE_SESSION_GENRE_TYPE;
      });
      setFreeSessionGenres(filteredGenres);
    }

    if (user.cowriting) {
      const filteredGenres = genres.filter(genre => {
        return genre.player_type === USER_TYPE && genre.genre_type === COWRITING_GENRE_TYPE;
      });
      setCoWritingSessionGenres(filteredGenres);
    }

    if (user.traditional_band) {
      const filteredGenres = genres.filter(genre => {
        return genre.player_type === USER_TYPE && genre.genre_type === TRADITIONAL_BAND_GENRE_TYPE;
      });
      setTraditionalBandSessionGenres(filteredGenres);
    }

    if (user.virtual_band) {
      const filteredGenres = genres.filter(genre => {
        return genre.player_type === USER_TYPE && genre.genre_type === VIRTUAL_BAND_GENRE_TYPE;
      });
      setVirtualBandSessionGenres(filteredGenres);
    }
  }, []);

  return (
    <div>
      {user.paid_sessions && <PaidSessionInterests genres={paidSessionGenres} hourlyRate={user.paid_sessions_hourly_rate} dailyRate={user.paid_sessions_daily_rate} />}

      {user.free_sessions && <FreeSessionInterests genres={freeSessionGenres} />}

      {user.cowriting && <CoWritingSessionInterests genres={coWritingSessionGenres} purpose={user.cowriting_purpose } />}

      {user.traditional_band && <TraditioalBandSessionInterests genres={traditionalBandSessionGenres} commitment={user.traditional_band_commitment} touring={user.traditional_band_touring} />}

      {user.virtual_band && <VirtualBandSessionInterests genres={virtualBandSessionGenres} commitment={user.virtual_band_commitment}/>}

    </div>
  );
};

JKProfileInterests.propTypes = {
  user: PropTypes.object
}

const FreeSessionInterests = ({ genres }) => {
  const genresList = genres.map(genre => titleize(genre.genre_id)).join(', ');
  
  return (
    <div>
      <div>I'm interested in playing free gigs</div>
      <ul>
        <li>Genre(s): {genres.length > 0 ? genresList : 'Not specified'}</li>
      </ul>
    </div>
  );
};

const PaidSessionInterests = ({ genres, hourlyRate, dailyRate }) => {
  const genresList = genres.map(genre => titleize(genre.genre_id)).join(', ');

  return (
    <div>
      <div>I'm interested in playing paid gigs</div>
      <ul>
        <li>Genre(s): {genres.length > 0 ? genresList : 'Not specified'}</li>
        <li>Hourly rate: {hourlyRate ? currencyFormat(hourlyRate/100.0) : 'Not specified'}</li>
        <li>Daily rate: {dailyRate ? currencyFormat(dailyRate/100.0) : 'Not specified'}</li>
      </ul>
    </div>
  );
};

const TraditioalBandSessionInterests = ({ genres, commitment, touring }) => {
  const genresList = genres.map(genre => titleize(genre.genre_id)).join(', ');
  const bandCommitmentMap = {
    "1": "infrequent",
    "2": "once a week",
    "3": "2-3 times a week",
    "4": "4+ times a week"
  };

  let canTour = ''; 
  canTour = touring ? "Yes" : (canTour === false ? "No" : 'Not specified')

  return (
    <div>
      <div>I'm interested in forming traditional band(s)</div>
      <ul>
        <li>Genre(s): {genres.length > 0 ? genresList : 'Not specified'}</li>
        <li>Commitment: {commitment ? bandCommitmentMap[commitment] : 'Not specified' }</li>
        <li>Touring: {canTour}</li>
      </ul>
    </div>
  );
};

const VirtualBandSessionInterests = ({ genres, commitment }) => {
  const genresList = genres.map(genre => titleize(genre.genre_id)).join(', ');
  const bandCommitmentMap = {
    "1": "infrequent",
    "2": "once a week",
    "3": "2-3 times a week",
    "4": "4+ times a week"
  };

  return (
    <div>
      <div>I'm interested in forming virtual band(s)</div>
      <ul>
        <li>Genre(s): {genres.length > 0 ? genresList : 'Not specified'}</li>
        <li>Commitment: {commitment ? bandCommitmentMap[commitment] : 'Not specified' }</li>
      </ul>
    </div>
  );
};

const CoWritingSessionInterests = ({ genres, purpose }) => {
  const genresList = genres.map(genre => titleize(genre.genre_id)).join(', ');
  const purposeMap = {
    "1": "just for fun",
    "2": "sell music"
  }
  return (
    <div>
      <div>I'm interested in co-writing - {purpose}</div>
      <ul>
        <li>Genre(s): {genres.length > 0 ? genresList : 'Not specified'}</li>
        <li>Purpose: {purpose ? purposeMap[purpose] : 'Not specified'}</li>
      </ul>
    </div>
  );
};

export default JKProfileInterests;
