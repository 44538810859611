import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Card, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import ListGroup from 'reactstrap/es/ListGroup';
import ListGroupItem from 'reactstrap/es/ListGroupItem';
import { isIterableArray } from '../../helpers/utils';
import FalconCardHeader from '../common/FalconCardHeader';
import Notification from '../notification/JKNotification';
import { Scrollbar } from 'react-scrollbars-custom';
import { useAuth } from '../../context/UserAuth';
import { readNotifications } from '../../helpers/rest';
import useNotifications from '../../hooks/useNotifications';

const JKNotificationDropdown = () => {
  const { currentUser, isAuthenticated } = useAuth();
  const { 
    notifications, 
    offset, 
    setOffset, 
    next, 
    unread_total, 
    loadNotifications, 
  } = useNotifications(currentUser);

  const LIMIT = 20;
  const MAX_COUNT_ON_BADGE = 99;

  const [isOpen, setIsOpen] = useState(false);
  const [isAllRead, setIsAllRead] = useState(false);

  const scrollbar = useRef();

  // Handler
  const handleToggle = e => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      readNotifications(currentUser.id)
        .then(() => {
          setIsAllRead(true);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [isOpen]);

  useEffect(() => {
    if(isAuthenticated)
      loadNotifications();
  }, [currentUser]);

  useEffect(() => {
    if (isAuthenticated && offset > 0 && next !== null) {
      loadNotifications();
    }
  }, [offset]);

  const handleScrollStop = scrollValues => {
    //update offset when scroll to bottom
    if (scrollValues.scrollTop === scrollValues.scrollHeight - scrollValues.clientHeight) {
      setOffset(prev => prev + LIMIT);
    }
  };

  return (
    <>
      {isAuthenticated && (
        <Dropdown
          nav
          inNavbar
          className="mx-3"
          isOpen={isOpen}
          toggle={handleToggle}

          // onMouseOver={() => {
          //   let windowWidth = window.innerWidth;
          //   windowWidth > 992 && setIsOpen(true);
          // }}
          // onMouseLeave={() => {
          //   let windowWidth = window.innerWidth;
          //   windowWidth > 992 && setIsOpen(false);
          // }}
        >
          <DropdownToggle
            nav
            className={classNames('px-0', {
              '': !isAllRead
            })}
          >
            {isIterableArray(notifications) && notifications.length > 0 && !isAllRead && unread_total > 0 && (
              <div className="num-circle" onClick={handleToggle}>
                {unread_total < MAX_COUNT_ON_BADGE ? unread_total : `${MAX_COUNT_ON_BADGE}+`}
              </div>
            )}
            <FontAwesomeIcon
              icon={['fas', 'bell']}
              transform="shrink-5"
              className="fs-4 bell-icon"
              onClick={handleToggle}
            />
          </DropdownToggle>
          <DropdownMenu right className="dropdown-menu-card" data-testid="notificationDropdown">
            <Card className="card-notification shadow-none" style={{ maxWidth: '20rem' }}>
              <FalconCardHeader className="card-header" title="Notifications" titleTag="h6" light={false}>
                <div className="card-link font-weight-normal" onClick={handleToggle}>
                  <Link className="card-link d-block" to="/notifications">
                    View all
                  </Link>
                </div>
              </FalconCardHeader>
              <>
                <ListGroup flush className="font-weight-normal fs--1">
                  <Scrollbar
                    ref={scrollbar}
                    onScrollStop={handleScrollStop}
                    style={{ width: '100%', height: 350 }}
                    mobileNative={true}
                    trackClickBehavior="step"
                  >
                    {isIterableArray(notifications)  &&
                      notifications.map(notification => (
                        <ListGroupItem
                          key={`notification-drop-item-${notification.notification_id}`}
                          onClick={handleToggle}
                        >
                          <Notification notification={notification} classNames="bg-200" flush />
                        </ListGroupItem>
                      ))}
                  </Scrollbar>
                </ListGroup>
              </>
            </Card>
          </DropdownMenu>
        </Dropdown>
      )}
    </>
  );
};

export default JKNotificationDropdown;
