import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Form, FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm, Controller, set } from 'react-hook-form';
import { toast } from 'react-toastify';

import JKModalDialog from '../common/JKModalDialog';

import { getSubscription, changeSubscription } from '../../helpers/rest';

import { getDisplayNamePrice, getDisplayCycle } from '../../helpers/subscriptionHelper';

function JKSubscriptionPlan({ userPlan, setUserPlan, getDisplayName }) {
  const { t } = useTranslation('account');

  const [plans, setPlans] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const toggleAlert = () => setShowAlert(!showAlert);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
    getValues
  } = useForm({
    defaultValues: {
      plan_code: ''
    }
  });

  useEffect(() => {
    if (userPlan) {
      setValue('plan_code', userPlan.desired_plan_code || "");
    }
  }, [userPlan]);


  useEffect(() => {
    if (window.gon) {
      const monthly = [{ value: 'monthly', isDisabled: true, label: '-------- MONTHLY PLANS --------' }];
      const yearly = [{ value: 'yearly', isDisabled: true, label: '-------- YEARLY PLANS --------' }];
      window.gon.global.subscription_codes.forEach(plan => {
        if (plan.cycle === 'month') {
          monthly.push({ value: plan.id || '', label: `${plan.name} ($${plan.price.toFixed(2)}/${plan.cycle})` });
        }
        if (plan.cycle === 'year') {
          yearly.push({ value: plan.id || '', label: `${plan.name} ($${plan.price.toFixed(2)}/${plan.cycle})` });
        }
      });
      const all = [...monthly, ...yearly];
      setPlans(all);
    }
  }, [window.gon]);

  const handleChange = selectedOption => {
    console.log('selectedOption', selectedOption);
    setError('plan_code', { type: 'manual', message: '' });
    setValue('plan_code', selectedOption.value);
  };

  // const getDisplayNamePrice = planCode => {
  //   if (planCode == '') {
  //     planCode = null;
  //   }
  //   const plan = window.gon.global.subscription_codes.find(plan => plan.id === planCode);
  //   if (plan) {
  //     return plan.price;
  //   }
  //   return `Unknown plan code=${planCode}`;
  // };

  // const getDisplayCycle = planCode => {
  //   if (planCode == '') {
  //     planCode = null;
  //   }
  //   for (const subscriptionCode of window.gon.global.subscription_codes) {
  //     if (planCode === subscriptionCode.id) {
  //       if (subscriptionCode.cycle === 'year') {
  //         return 'annual';
  //       } else {
  //         return subscriptionCode.cycle + 'ly';
  //       }
  //     }
  //   }
  //   return `Unknown plan code=${planCode}`;
  // };

  const onSubmit = () => {
    const planCode = getValues('plan_code');
    if (planCode === null) {
      setError('plan_code', {
        type: 'manual',
        message: t('subscription.current_plan.validations.subscription_plan.required')
      });
      setSubmitting(false);
      return;
    } else if (planCode === '') {
      setAlertText(t('subscription.alerts.changed_to_free_plan'));
      toggleAlert();
    } else {
      setAlertText(
        `You have selected the ${getDisplayName(planCode).toUpperCase()} ${getDisplayCycle(
          planCode
        ).toUpperCase()} PLAN and will be charged US$${getDisplayNamePrice(planCode)}.`
      );
      toggleAlert();
    }
  };

  const updateSubscription = () => {
    toggleAlert();
    setSubmitting(true);
    const planCode = getValues('plan_code');
    changeSubscription(planCode)
      .then(resp => {
        console.log('changeSubscription', resp);
        if (resp.ok) {
          getSubscription()
            .then(resp => resp.json())
            .then(data => {
              console.log('subscriptionData', data);
              setUserPlan(data);
              setValue('plan_code', data.desired_plan_code);
              toast.success(t('subscription.alerts.changed_plan_successfully'));
            })
            .catch(error => {
              console.log('subscriptionError', error);
            });
        }
      })
      .catch(error => {
        setAlertText(`${t('subscription.alerts.failed_to_change_plan')}: ${error}`);
        console.log('changeSubscriptionError', error);
        setValue('plan_code', userPlan.desired_plan_code);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const cancelUpdateSubscription = () => {
    setValue('plan_code', userPlan.desired_plan_code);
    setSubmitting(false);
    toggleAlert();
  };

  return (
    <>
      <Card>
        <CardHeader>
          <h5>{t('subscription.current_plan.title')}</h5>
        </CardHeader>
        <CardBody className="bg-light" style={{ minHeight: 300, minWidth: 280 }}>
          <small>{t('subscription.current_plan.help_text')}</small>
          <Form
            className="mt-2"
            onSubmit={handleSubmit(onSubmit)}
            data-testid="changeSubscriptionForm"
          >
            {plans && userPlan && (
              <>
                <FormGroup>
                  <Label for="plan_code">{t('subscription.current_plan.subscription_plan')}</Label>
                  <Controller
                    name="plan_code"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      const plan = plans.find(plan => plan.value === value);
                      if (!plan) {
                        return <Select classNamePrefix="select-plan" data-testid="countrySelect" onChange={handleChange} options={plans} />;
                      }
                      return (
                        <Select classNamePrefix="select-plan" data-testid="countrySelect" value={plan} onChange={handleChange} options={plans} />
                      );
                    }}
                  />
                  {errors.plan_code && <div className="text-danger">{errors.plan_code.message}</div>}
                </FormGroup>
                <div className="d-flex justify-content-end">
                  <input
                    type="submit"
                    className="btn btn-primary"
                    value={t('subscription.current_plan.submit')}
                    disabled={submitting || [userPlan.desired_plan_code].includes(getValues('plan_code'))}
                  />
                  <span className="ml-2">{submitting && <FontAwesomeIcon icon="spinner" />}</span>
                </div>
                <div className='mt-3'>
                  <small>
                    { t('subscription.current_plan.notice.part1') }<a target='_blank' href="https://jamkazam.freshdesk.com/support/solutions/articles/66000122535-what-are-">{ t('subscription.current_plan.notice.click_here') }</a>{ t('subscription.current_plan.notice.part2') }
                  </small>
                </div>
              </>
            )}
          </Form>
        </CardBody>
      </Card>
      <JKModalDialog
        show={showAlert}
        onToggle={toggleAlert}
        title={t('subscription.alerts.title')}
        data-testid="subscription-plan-update-notification"
        size="md"
        modalFooterButtons={[
          {
            text: t('cancel', { ns: 'common' }),
            color: 'secondary',
            onClick: cancelUpdateSubscription,
            disabled: submitting
          },
          {
            text: t('ok', { ns: 'common' }),
            color: 'primary',
            onClick: updateSubscription,
            disabled: submitting
          }
        ]}
      >
        {alertText}
      </JKModalDialog>
    </>
  );
}

export default JKSubscriptionPlan;
