import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

const JKJamTrackArtists = ({ artists, showArtists, onSelect }) => {
  const [expanded, setExpanded] = useState(false);
  const handleClick = artist => {
    onSelect(artist);
  };

  const toggleMoreArtists = e => {
    e.preventDefault();
    setExpanded(!expanded);
  }

  return (
    showArtists && (
      <>
        <Row>
          <Col>
            <strong>Search Results: Artists</strong>
          </Col>
        </Row>
        {artists.length > 0 ? (
          <>
            <Row className="mb-2">
              <Col>
                {artists.map((artist, index) => (
                  <a
                    href="#"
                    key={`${index}_${artist.original_artist}`}
                    onClick={() => handleClick(artist)}
                    className={index + 1 > 6 && !expanded ? 'd-none' : null}
                  >
                    <span className='mr-4 pb-1'>
                      {artist.original_artist}
                    </span>
                  </a>
                ))}
              </Col>
            </Row>
            {artists.length > 6 && (
              <Row className='mt-1 mb-1'>
                <Col>
                  <a href="#" onClick={toggleMoreArtists}>
                    {expanded ? 'Show fewer artists' : 'Show all artists'}
                  </a>
                </Col>
              </Row>
            )}
          </>
        ) : (
          <Row className="mb-2">
            <Col>No matching artists</Col>
          </Row>
        )}
      </>
    )
  );
};

JKJamTrackArtists.propTypes = {
  artists: PropTypes.array.isRequired,
  showArtists: PropTypes.bool,
  onSelect: PropTypes.func
};

JKJamTrackArtists.defaultProps = {
  artists: [],
  showArtists: false,
  onSelect: () => {}
};

export default JKJamTrackArtists;
