import React from 'react';
import ProfileAvatar from '../profile/JKProfileAvatar'
import TimeAgo from '../common/JKTimeAgo';
import { useAuth } from '../../context/UserAuth';
import { useDispatch, useSelector } from 'react-redux';
import { acceptFriendRequest, fetchPerson } from '../../store/features/peopleSlice';
import { Button } from 'reactstrap';
import useUserProfile from '../../hooks/useUserProfile';

function JKFriendRequestNotification(props) {
  const { formatted_msg, created_at, friend_request_id, source_user_id } = props.notification;
  const handleOnAccept = props.handleOnAccept;
  const { currentUser } = useAuth();
  const dispatch = useDispatch();
  const user = useSelector(state => state.people.people.find(person => person.id === source_user_id));
  const { photoUrl } = useUserProfile(user); // user is the person who sent the message

  const handleClick = async event => {
    event.stopPropagation();
    const options = {
      userId: currentUser.id,
      friend_request_id: friend_request_id,
      status: 'accept'
    };

    dispatch(acceptFriendRequest(options))
    .unwrap()
    .then((response) => {
      handleOnAccept()  
    })
    .catch((error) => {
      console.log(error);
    })
    
  };

  return (
    <>
      <div className="notification-avatar mr-3">
        <ProfileAvatar src={photoUrl} />
      </div>
      <div className="notification-body">
        <p className="mb-1">{formatted_msg}</p>
        <span className="notification-time">
          <TimeAgo date={created_at} />
        </span>
        <div>
          <Button
            color="secondary"
            size="sm"
            outline={true}
            className="fs--1 px-2 py-1 mr-1"
            onClick={handleClick}
          >
            Accept
          </Button>
        </div>
      </div>
    </>
  );
}

export default JKFriendRequestNotification;
