import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import { useTranslation } from 'react-i18next';

import JKSubscriptionPlan from '../profile/JKSubscriptionPlan';
import JKSubscriptionPlaytime from '../profile/JKSubscriptionPlaytime';

import { getSubscription } from '../../helpers/rest';

import { getDisplayName } from '../../helpers/subscriptionHelper';

const JKAccountSubscription = () => {
  const { t } = useTranslation('account');
  const [userPlan, setUserPlan] = useState(null);

  useEffect(() => {
    getSubscription()
      .then(resp => resp.json())
      .then(data => {
        setUserPlan(data);
      })
      .catch(error => {
        console.log('subscriptionError', error);
      });
  }, []);

  // const getDisplayName = planCode => {
  //   if (planCode == '') {
  //     planCode = null;
  //   }
  //   const plan = window.gon.global.subscription_codes.find(plan => plan.id === planCode);
  //   if (plan) {
  //     return plan.name;
  //   }
  //   return `Unknown plan code=${planCode}`;
  // };

  return (
    <Card>
      <FalconCardHeader title={t('subscription.page_title')} titleClass="font-weight-bold" />
      <CardBody className="pt-3" style={{ backgroundColor: '#edf2f9' }}>
        {userPlan ? (
          <Row>
            <Col className="mb-2">
              <JKSubscriptionPlan userPlan={userPlan} setUserPlan={setUserPlan} getDisplayName={getDisplayName} />
            </Col>
            <Col>
              <JKSubscriptionPlaytime userPlan={userPlan} getDisplayName={getDisplayName} />
            </Col>
            <Col className='d-none d-sm-block' />
          </Row>
        ) : 'Loading...' }
      </CardBody>
    </Card>
  );
};

export default JKAccountSubscription;
