import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getPurchasedJamTracks } from '../../helpers/rest';

const initialState = {
  jamTracks: [],
  status: 'idle',
  error: null,
  next: null
};

export const fetchMyJamTracks = createAsyncThunk('jamTracks/fetchMyJamTracks', async (options, thunkAPI) => {
  const response = await getPurchasedJamTracks(options);
  return response.json();
});

export const myJamTracksSlice = createSlice({
  name: 'jamTracks',
  initialState,
  reducers: {
    addJamTrack: (state, action) => {
      state.jamTracks.push(action.payload);
    },
    // updateJamTrack: (state, action) => {
    //   const { id, name } = action.payload;
    //   const existingJamTrack = state.jamTracks.find(jamTrack => jamTrack.id === id);
    //   if (existingJamTrack) {
    //     existingJamTrack.name = name;
    //   }
    // },
    deleteJamTrack: (state, action) => {
      const { id } = action.payload;
      state.jamTracks = state.jamTracks.filter(jamTrack => jamTrack.id !== id);
    },
    filterJamTracks: (state, action) => {
      state.jamTracks = state.jamTracks.filter(jamTrack =>
        jamTrack.name.toLowerCase().includes(action.payload.toLowerCase())
      );
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchMyJamTracks.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchMyJamTracks.fulfilled, (state, action) => {
        const append = action.meta.arg.append;
        //--- amend the state to include only unique jamTracks
        if (append) {
          const records = new Set([...state.jamTracks, ...action.payload.jamtracks]);
          const unique = [];
          records.map(x => (unique.filter(a => a.id === x.id).length > 0 ? null : unique.push(x)));
          state.jamTracks = unique;
          state.next = action.payload.next;
          state.status = 'succeeded';
        } else {
          state.status = 'succeeded';
          state.jamTracks = action.payload.jamtracks;
          state.next = action.payload.next;
        }
        //---
      })
      .addCase(fetchMyJamTracks.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export const { addJamTrack, deleteJamTrack, filterJamTracks } = myJamTracksSlice.actions;
export default myJamTracksSlice.reducer;
