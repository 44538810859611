export const getDisplayName = planCode => {
  if (planCode == '') {
    planCode = null;
  }
  const plan = window.gon.global.subscription_codes.find(plan => plan.id === planCode);
  if (plan) {
    return plan.name;
  }
  return `Unknown plan code=${planCode}`;
};

export const getDisplayNamePrice = planCode => {
  if (planCode == '') {
    planCode = null;
  }
  const plan = window.gon.global.subscription_codes.find(plan => plan.id === planCode);
  if (plan) {
    return plan.price;
  }
  return `Unknown plan code=${planCode}`;
};

export const getDisplayCycle = planCode => {
  if (planCode == '') {
    planCode = null;
  }
  for (const subscriptionCode of window.gon.global.subscription_codes) {
    if (planCode === subscriptionCode.id) {
      if (subscriptionCode.cycle === 'year') {
        return 'annual';
      } else {
        return subscriptionCode.cycle + 'ly';
      }
    }
  }
  return `Unknown plan code=${planCode}`;
};

export const planNameWithCycle = planCode => {
  return getDisplayName(planCode) + ' (' + getDisplayCycle(planCode) + ')';
};

export const displayTime = until_time => {
  if (until_time < 0) return 'no time';

  const untilTime = getTimeRemaining(until_time * 1000);

  let timeString = '';
  if (untilTime.days !== 0) timeString += `${untilTime.days} days, `;
  if (untilTime.hours !== 0 || timeString.length > 0) timeString += `${untilTime.hours} hours, `;
  if (untilTime.minutes !== 0 || timeString.length > 0) timeString += `${untilTime.minutes} minutes `;
  if (timeString === '') timeString = 'now!';

  return timeString;
};

const getTimeRemaining = t => {
  if (t < 0) t = -t;

  const seconds = Math.floor((t / 1000) % 60);
  const minutes = Math.floor((t / 1000 / 60) % 60);
  const hours = Math.floor((t / (1000 * 60 * 60)) % 24);
  const days = Math.floor(t / (1000 * 60 * 60 * 24));

  return {
    total: t,
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds
  };
};



