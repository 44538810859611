import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { addFriend as connect, removeFriend as disconnect } from '../../helpers/rest';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import { useBrowserQuery } from '../../context/BrowserQuery';

const JKConnectButton = props => {
  const { user, currentUser, addContent, removeContent, cssClasses } = props;
  const [isFriend, setIsFriend] = useState(false);
  const [pendingFriendRequest, setPendingFriendRequest] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    if (user) {
      setIsFriend(user.is_friend);
      setPendingFriendRequest(user.pending_friend_request);
    }
  }, [user]);

  const queryString = useBrowserQuery();

  useEffect(() => {
    const openWin = queryString.get('open');
    const userId = queryString.get('id');
    //sending friend request if directly reqested to do so
    //by query string params (coming from weekly new user match email link)
    if (openWin && userId && userId === user.id && !user.isFriend && !user.pending_friend_request) {
      if (openWin === 'connect') {
        addFriend();
      }
    }
  }, []);

  const addFriend = () => {
    setShowConfirmModal(!showConfirmModal);
    setPendingFriendRequest(true);
    connect(
      currentUser.id,
      user.id
    )
      .then(resp => {
        if (resp.ok && resp.status === 201) {
          toast.success(`Success! Your friend request has been sent to ${user.name}.`);
        }
      })
      .catch(err => {
        toast.error('An error encountered when sending friend request.');
        setPendingFriendRequest(false);
      });
  };

  const confirm = () => {
    setShowConfirmModal(!showConfirmModal);
  };

  const removeFriend = () => {
    disconnect(currentUser.id, user.id)
      .then(resp => {
        if (resp.ok) {
          setIsFriend(false);
          setPendingFriendRequest(false);
        }
      })
      .catch(err => {
        toast.error('An error encountered when removing friend');
      });
  };

  const buttonTitle = () => {
    let title;
    if (pendingFriendRequest) {
      title = 'Delete pending friend request';
    } else if (!isFriend) {
      title = 'Send friend request';
    } else if (isFriend) {
      title = 'Unfriend this person';
    }
    return title;
  };

  return (
    <>
      {!isFriend ? (
        <>
          <button
            className={`btn btn-primary ${cssClasses}`}
            data-testid="connect"
            disabled={pendingFriendRequest}
            onClick={addFriend}
            title={buttonTitle()}
          >
            {addContent}
          </button>
          {/* <ConnectConfirmModal
            show={showConfirmModal}
            setShow={setShowConfirmModal}
            user={user}
            handleOnConfirm={addFriend}
          /> */}
        </>
      ) : (
        <>
          <button
            className={`btn btn-primary ${cssClasses}`}
            data-testid="disconnect"
            onClick={() => setShowConfirmModal(!showConfirmModal)}
            title={buttonTitle()}
          >
            {removeContent}
          </button>
          <RemoveConfirmModal
            show={showConfirmModal}
            setShow={setShowConfirmModal}
            user={user}
            handleOnConfirm={removeFriend}
          />
        </>
      )}
    </>
  );
};

JKConnectButton.propTypes = {
  user: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  addContent: PropTypes.element.isRequired,
  removeContent: PropTypes.element.isRequired
};

// const ConnectConfirmModal = props => {
//   const { className, show, setShow, user, handleOnConfirm } = props;

//   //const [modal, setModal] = useState(show);

//   const toggle = () => setShow(!show);

//   const accept = () => {
//     handleOnConfirm();
//   };

//   return (

//       <Modal isOpen={show} toggle={toggle} className={className} centered={true} data-testid="confirmFriendRequestModal">
//         <ModalHeader toggle={toggle}>Send Friend Request</ModalHeader>
//         <ModalBody>Send a friend request to {user.name}.</ModalBody>
//         <ModalFooter>
//           <Button color="primary" onClick={accept}>
//             Yes
//           </Button>{' '}
//           <Button color="secondary" onClick={toggle}>
//             No
//           </Button>
//         </ModalFooter>
//       </Modal>

//   );
// };

const RemoveConfirmModal = props => {
  const { className, show, setShow, user, handleOnConfirm } = props;

  const toggle = () => setShow(!show);

  const accept = () => {
    handleOnConfirm();
  };

  return (
    <Modal isOpen={show} toggle={toggle} className={className} centered={true} data-testid="confirmRemoveFriendModal">
      <ModalHeader toggle={toggle}>Remove Friend</ModalHeader>
      <ModalBody>Are you sure to remove {user.name} from your friend list?</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={accept}>
          Yes
        </Button>{' '}
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default JKConnectButton;
