import React, { Fragment, useContext } from 'react';
//import PropTypes from 'prop-types';
import AppContext from '../../../context/Context';
import { Alert, Card, CardBody, CardFooter, Media, Table } from 'reactstrap';
import FalconCardHeader from '../../common/FalconCardHeader';
import {isIterableArray } from '../../../helpers/utils';
import Flex from '../../common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useShoppingCart } from '../../../hooks/useShoppingCart';

const CheckoutAside = () => {
  const { currency } = useContext(AppContext);
  const { shoppingCart, cartTotal } = useShoppingCart();
  return (
    <Card>
      <FalconCardHeader title="Order Summary" titleTag="h5" light={false}>
        {/* <ButtonIcon
          color="link"
          size="sm"
          tag={Link}
          icon="pencil-alt"
          className="btn-reveal text-600"
          to="/e-commerce/shopping-cart"
        /> */}
      </FalconCardHeader>
      {isIterableArray(shoppingCart) ? (
        <Fragment>
          <CardBody className="pt-0">
            <Table borderless className="fs--1 mb-0">
              <tbody>
                {shoppingCart.map((shoppingCartItem) => {
                  return (
                    <tr className="border-bottom" key={shoppingCartItem.id}>
                      <th className="pl-0">
                      { shoppingCartItem.product_info.sale_display }
                      </th>
                      <th className="pr-0 text-right">
                        {currency}
                        {shoppingCartItem.product_info.total_price }
                      </th>
                    </tr>
                  );
                })}
                {/* <tr className="border-bottom">
                  <th className="pl-0">Subtotal</th>
                  <th className="pr-0 text-right">
                    {currency}
                    {subTotal}
                  </th>
                </tr> */}

                {/* <tr className="border-bottom">
                  <th className="pl-0">Shipping</th>
                  <th className="pr-0 text-right text-nowrap">
                    + {currency}
                    {calculatedShippingCost}
                  </th>
                </tr> */}
                <tr>
                  <th className="pl-0 pb-0">Total</th>
                  <th className="pr-0 text-right pb-0 text-nowrap">
                    {currency}
                    {cartTotal}
                  </th>
                </tr>
               
              </tbody>
            </Table>
          </CardBody>
          <CardFooter tag={Flex} justify="between" className="bg-100">
            <div className="font-weight-semi-bold">Payable Total</div>
            <div className="font-weight-bold">
              {currency}
              {cartTotal}
            </div>
          </CardFooter>
        </Fragment>
      ) : (
        <CardBody className="p-0">
          <Alert color="warning" className="mb-0 rounded-0 overflow-hidden">
            <Media className="align-items-center">
              <FontAwesomeIcon icon={['far', 'dizzy']} className="fs-5" />
              <Media body className="ml-3">
                <p className="mb-0">You have no items in your shopping cart. Go ahead and start shopping!</p>
              </Media>
            </Media>
          </Alert>
        </CardBody>
      )}
    </Card>
  );
};

// CheckoutAside.propTypes = {
// };

export default CheckoutAside;
