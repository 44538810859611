import React from 'react'
import { Card, CardBody } from 'reactstrap'
import FalconCardHeader from '../common/FalconCardHeader'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom'

const JKCheckoutSuccess = () => {
  const {t} = useTranslation('checkoutSuccess')
  return (
    <Card>
    <FalconCardHeader title={t('page_title')} titleClass="font-weight-semi-bold" />
    <CardBody className="pt-0 text-center mt-4">
      <p className="text-muted">Thank you for your order! We'll send you an order confirmation email shortly.</p>
      <p>
        Click the button below to start using your new JamTracks.
      </p>
      <p>
        <Link to="/jamtracks/my" className="btn btn-primary">
          {t('my_jamtracks')}
        </Link>
      </p>
      <div>
        <p>
          You can also play with your JamTracks in the <a href="https://www.jamkazam.com/downloads" target='_blank'>JamKazam desktop app</a>, available for Windows and Mac.
        </p>
      </div>
    </CardBody>
  </Card>
  )
}

export default JKCheckoutSuccess