import React, { useState, useEffect } from 'react';
import { Button, Tooltip } from "reactstrap";
import JKMessageModal from './JKMessageModal';
import { useBrowserQuery } from '../../context/BrowserQuery';

const JKMessageButton = props => {
  const { currentUser, user, cssClasses, children, size, color, outline } = props;
  const [showModal, setShowModal] = useState(false);
  //const [isFriend, setIsFriend] = useState(false);
  const [pendingFriendRequest, setPendingFriendRequest] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  useEffect(() => {
    //setIsFriend(user.is_friend);
    setPendingFriendRequest(user.pending_friend_request);
  }, [user]);


  const queryString = useBrowserQuery();

  useEffect(() => {
    const openWin = queryString.get('open');
    const userId  =  queryString.get('id')
    //openning chat window if directly reqested to do so 
    //by query string params (coming from weekly new user match email link)
    if(openWin && userId && userId === user.id){
      if(openWin === 'message'){
        setShowModal(!showModal)
      }
    }
  }, [])
  

  return (
    <>
      <JKMessageModal show={showModal} setShow={setShowModal} user={user}  />
      <Button
        id={"text-message-user-" + user.id}
        onClick={() => setShowModal(!showModal)}
        color={color}
        size={size}
        outline={outline}
        className={cssClasses}
        data-testid="message"
        //disabled={!isFriend}
      >
        {children}
      </Button>
      <Tooltip
        placement='top'
        isOpen={tooltipOpen}
        target={"text-message-user-" + user.id}
        toggle={toggleTooltip}
      >
        {/* {
          isFriend ? 'Send a message' : 'You can message this user once you are friends'
        } */}
        Send a message
      </Tooltip>
    </>
  );
};

JKMessageButton.defaultProps = { 
  color: 'primary', 
  size: 'sm',
  outline: false 
};

export default JKMessageButton;
