// import { useCookies } from 'react-cookie';
import { useMemo } from 'react';

import Cookies from 'universal-cookie';

export const useCheckout = () => {
  const cookieName = 'preserve_billing';
  // const [setCookie, removeCookie, cookies] = useCookies([cookieName]);
  const cookies = new Cookies(null, { path: '/' });

  const setPreserveBillingInfo = () => {
    const date = new Date();
    const minutes = 10;
    date.setTime(date.getTime() + minutes * 60 * 1000);
    //expires if there is a cookie with the same name
    //removeCookie(cookieName, { path: '/' });
    cookies.remove(cookieName, { path: '/' });
    //set the new cookie
    //setCookie(cookieName, 'jam', { path: '/', expires: date });
    cookies.set(cookieName, 'jam', { path: '/', expires: date });
  };

  const shouldPreserveBillingInfo = useMemo(() => {
    return cookies.get(cookieName) === 'jam';
  }, [cookies]);

  const refreshPreserveBillingInfo = () => {
    if (shouldPreserveBillingInfo) {
      setPreserveBillingInfo();
    }
  }

  const deletePreserveBillingInfo = () => {
    cookies.remove(cookieName, { path: '/' });
    if(cookies.get(cookieName)){
      console.log('after deleting the preserve billing cookie, it still exists!');
    }
  }

  return {
    setPreserveBillingInfo,
    shouldPreserveBillingInfo,
    refreshPreserveBillingInfo,
    deletePreserveBillingInfo
  }
};
