import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import { Row, Col } from 'reactstrap';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { useSelector } from 'react-redux';

const JKJamTrackPlayer = () => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const fpPromise = FingerprintJS.load();
  const [audioUrl, setAudioUrl] = useState(null);
  const audioRef = useRef(null);
  const jamTrack = useSelector(state => state.jamTrack.jamTrack);

  useEffect(() => {
    if (jamTrack) {
      const opts = jamTrack.mixdowns.map(mix => ({ value: mix.id, label: mix.name })).filter(mix => mix.value !== 'temp');
      opts.unshift({ value: 'original', label: 'Original' });
      setOptions(opts);
      if (jamTrack.last_mixdown_id) {
        setSelectedOption(opts.find(opt => opt.value === jamTrack.last_mixdown_id));
      } else {
        setSelectedOption(opts[0]);
      }
    }
  }, [jamTrack]);

  const handleOnChange = selectedOption => {
    const option = options.find(opt => opt.value === selectedOption.value);
    setSelectedOption(option);
  };

  useEffect(() => {
    if (!selectedOption) {
      return;
    }

    if (selectedOption.value === 'original') {
      const audioUrl = getMasterTrack();
      setAudioUrl(audioUrl);
      if(audioRef.current)
        audioRef.current.load();
    } else {
      //it's a mixdown
      getMixdown().then(audioUrl => {
        setAudioUrl(audioUrl);
        if(audioRef.current)
          audioRef.current.load();
      });
    }
  }, [selectedOption]);

  const getMasterTrack = () => {
    const masterTrack = jamTrack.tracks.find(track => track.track_type === 'Master');
    if (masterTrack) {
      const audioUrl = masterTrack.preview_mp3_url;
      return audioUrl;
    }
  };

  const getMixdown = async () => {
    const activeMix = jamTrack.mixdowns.find(mix => mix.id === selectedOption.value);
    const fp = await fpPromise;
    const result = await fp.get();
    const audioUrl =
      process.env.REACT_APP_API_BASE_URL +
      `/mixdowns/${activeMix.id}/download.mp3?file_type=mp3&sample_rate=48&mark=${result.visitorId}`;
    return audioUrl;
  };

  return (
    <>
      <Select options={options} placeholder="Select Mix" onChange={handleOnChange} value={selectedOption} />
      <Row className="mt-2">
        <Col>
          {audioUrl && (
            <figure>
              <audio controls style={{ width: '100%' }} ref={audioRef}>
                <source src={audioUrl} type={`audio/${audioUrl.split('.').pop()}`} />
              </audio>
            </figure>
          )}
        </Col>
      </Row>
    </>
  );
};

export default JKJamTrackPlayer;