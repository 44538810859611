import React from 'react';
import routes from '../routes';
import { getAppFeatures } from '../helpers/rest';

const AppRoutesContext = React.createContext(null);

export const AppRoutesProvider = ({ children }) => {
  const [appRoutes, setAppRoutes] = React.useState([]);

  React.useEffect(() => {
    if (appRoutes.length === 0) {
      const env = process.env.REACT_APP_ENV;
      getAppFeatures(env)
        .then(response => {
          if (response) {
            return response.json();
          }
        })
        .then(features => {
          if (features.length === 0) {
            setAppRoutes(routes);
          } else {
            const _routes = routes.filter(route => {
              if(route.children && route.children.length > 0) {
                route.children = route.children.filter(child => {
                  return presentInNav(features, child);
                });
              }
              return presentInNav(features, route);
            });
            setAppRoutes(_routes);
          }
        });
    }
  }, []);

  const presentInNav = (features, route) => {
    return features.find(
      feature => route.to === feature.handle && feature.is_enabled && feature.feature_type === 'page' && feature.env === process.env.REACT_APP_ENV
    );
  };

  return <AppRoutesContext.Provider value={{ appRoutes }}>{children}</AppRoutesContext.Provider>;
};

export const useAppRoutes = () => React.useContext(AppRoutesContext);
