import React, {useEffect} from 'react'

const JKHelp = () => {
  const pageUrl = "https://www.jamkazam.com/corp/help"
  useEffect(() => {
    window.location.href = pageUrl;
    return () => {
    };
  }, [])
  return (
    <div>
      redirecting...
    </div>
  )
}

export default JKHelp;