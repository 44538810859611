import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPerson } from '../../store/features/sessionsSlice';
import { useResponsive } from '@farfetch/react-context-responsive';

import JKLatencyBadge from '../profile/JKLatencyBadge';
import JKProfileSidePanel from '../profile/JKProfileSidePanel';
import JKProfileAvatar from '../profile/JKProfileAvatar';
import PropTypes from 'prop-types';

function JKSessionUser({ user }) {
  const dispatch = useDispatch();
  const latencyData = useSelector(state => { 
    const userLatency = state.latency.latencies.find(l => l.user_id === user.id);
    return {
      ars_internet_latency: userLatency?.ars?.internet_latency,
      audio_latency: userLatency?.audio_latency
    }
  });
  const userData = useSelector(state => state.session.people.find(p => p.id === user.id));
  const [showSidePanel, setShowSidePanel] = useState(false);
  const { greaterThan } = useResponsive();

  const fetchPersonData = useCallback(async ()  => {
    await dispatch(fetchPerson({ userId: user.id })).unwrap();
  }, [user.id])

  // useEffect(() => {
  //   fetchPersonData();
  // }, [user.id])
  

  const toggleMoreDetails = async e => {
    e.preventDefault();
    try {
      if(!userData){
        await fetchPersonData();
      }
    } catch (error) {
      console.log(error);
    }

    setShowSidePanel(prev => !prev);
  };

  const truncate = (str, length = 20) => {
    return str.length > length ? str.substring(0, length - 3) + '...' : str;
  };

  return (
    <>
      {!greaterThan.sm ? (
        <div className="d-flex flex-row justify-content-between">
          <div className="avatar avatar-sm">
            <a href="/#" onClick={toggleMoreDetails}>
              <JKProfileAvatar src={user.photo_url} />
            </a>
          </div>
          <div className="ml-2 ms-2" style={{ width: '70%' }}>
            <a href="/#" onClick={toggleMoreDetails}>
              {user.name}
            </a>
          </div>
          <div className="ml-2 ms-2" style={{ marginRight: 'auto' }}>
            <JKLatencyBadge latencyData={latencyData} showBadgeOnly={true} />
          </div>
        </div>
      ) : (
        <div className="d-flex flex-row align-items-center">
            <a href="/#" onClick={toggleMoreDetails}>
              <JKProfileAvatar src={user.photo_url} />
            </a>
          <div className="ml-2 ms-2">
            <a href="/#" onClick={toggleMoreDetails}>
              {truncate(user.name)}
            </a>
          </div>
        </div>
      )}
      <JKProfileSidePanel show={showSidePanel} setShow={setShowSidePanel} user={userData} latencyData={latencyData} />
    </>
  );
}

JKSessionUser.propTypes = { user: PropTypes.object.isRequired };

export default JKSessionUser;
