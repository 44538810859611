import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Alert, Card, CardBody } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import { useTranslation } from 'react-i18next';
import { useResponsive } from '@farfetch/react-context-responsive';
import Loader from '../common/Loader';
import { isIterableArray } from '../../helpers/utils';
import JKFriendsList from '../people/JKFriendsList';
import JKFriendsSwiper from '../people/JKFriendsSwiper';
import { useAuth } from '../../context/UserAuth';
import { useDispatch, useSelector } from 'react-redux';
import { getFriends } from '../../helpers/rest';
import { filterFriendsByIds, sortFriends } from '../../store/features/friendsSlice';
import { fetchUserLatencies } from '../../store/features/latencySlice';

export const JKMyFriends = () => {
  const { t } = useTranslation();
  const { greaterThan, lessThan } = useResponsive();
  const friendListRef = useRef();
  const dispatch = useDispatch();
  const [showLoadMore, setShowLoadMore] = useState(false);
  const { currentUser } = useAuth();

  const friends = useSelector(state => state.friend.friends);
  const loadingStatus = useSelector(state => state.friend.loadingStatus);

  const goNextPage = () => {};

  const sortOptions = [
    { value: 'online', label: 'Online' },
    { value: 'alphabetical', label: 'Alphabetical' },
    { value: 'last_active', label: 'Last Active' }
  ];

  useEffect(() => {
    if (currentUser) {
      getFriends(currentUser.id)
        .then(async response => {
          const data = await response.json();
          const friendIds = data.map(friend => friend.id);
          //fetch friends with associated data
          dispatch(filterFriendsByIds({ userId: currentUser.id, ids: friendIds }));
          //fetch latencies for friends
          dispatch(fetchUserLatencies({ currentUserId: currentUser.id, otherUserIds: friendIds }));
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [currentUser]);

  const sortResults = e => {
    const sortValue = e.target.value;
    dispatch(sortFriends(sortValue));
  };

  return (
    <Card>
      <FalconCardHeader title={t('page_title', { ns: 'friends' })} titleClass="font-weight-bold">
        <div className={`d-flex ${ lessThan.sm? 'pt-2' : ''}`} >
          <span className="mr-2">Sort By:</span>
          <select onChange={sortResults}>
            {sortOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </FalconCardHeader>
      <CardBody className="pt-0">
        <>
          {loadingStatus === 'loading' && friends.length === 0 ? (
            <Loader />
          ) : isIterableArray(friends) ? (
            <>
              {greaterThan.sm ? (
                <Row className="mb-3 justify-content-between d-none d-md-block">
                  <div className="table-responsive-xl px-2" ref={friendListRef}>
                    <JKFriendsList friends={friends} isLoading={loadingStatus === 'loading'} />
                  </div>
                </Row>
              ) : (
                <Row className="swiper-container d-block d-md-none" data-testid="friendsSwiper">
                  <JKFriendsSwiper friends={friends} goNextPage={goNextPage} />
                </Row>
              )}
            </>
          ) : (
            loadingStatus === 'succeeded' &&
            friends.length ===
              0(
                <Row className="p-card">
                  <Col>
                    <Alert color="info" className="mb-0">
                      No Records!
                    </Alert>
                  </Col>
                </Row>
              )
          )}
        </>
      </CardBody>
    </Card>
  );
};

export default JKMyFriends;
