import React from 'react';
import { useResponsive } from '@farfetch/react-context-responsive';
import { Row, Col } from 'reactstrap';

const JKAffiliateSignup = ({signup}) => {
  const { greaterThan } = useResponsive();
  return (
    <>
      {greaterThan.sm ? (
        <tr>
          <td>{signup.month}</td>
          <td>{signup.visits}</td>
          <td>{signup.signups}</td>
        </tr>
      ) : (
        <>
          <Row className="mb-1">
            <Col>{signup.month}</Col>
          </Row>
          <Row className="mb-1">
            <Col>
              <small>Visits: {signup.visits}</small>
            </Col>
          </Row>
          <Row className="mb-1">
            <Col>
              <small>Signups: {signup.signups}</small>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default JKAffiliateSignup;
