import React from 'react';
import PropTypes from 'prop-types';

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import JKAffiliateSignup from './JKAffiliateSignup';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

import { Card, CardBody, CardHeader } from 'reactstrap';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const JKAffiliateSignupsSwiper = ({ signups }) => {

  return (
    <>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        //onSlideChange={() => console.log('slide change')}
        onSlideNextTransitionEnd={swiper => {
          if(swiper.isEnd){
            //goNextPage()
          }
        }}
        pagination={{
          clickable: true,
          type: 'custom'
        }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }}
      >
        {signups.map((signup, index) => (
          <SwiperSlide key={`${signup.day}-${index}-mobile`}>
            <Card className="swiper-card">
              <CardHeader className="text-center bg-200">
                <h4 className="d-inline-block align-center mt-1">Signups</h4>
              </CardHeader>
              <CardBody>
                <JKAffiliateSignup signup={signup} /> 
              </CardBody>
            </Card>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="py-4 px-6 bg-white border-top w-100 fixed-bottom">
        <div className="swiper-pagination" />
        <div className="swiper-button-prev" />
        <div className="swiper-button-next" />
      </div>
    </>
  );
};

JKAffiliateSignupsSwiper.propTypes = {
  signups: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
};

export default JKAffiliateSignupsSwiper;
