import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import AppContext from '../../../context/Context';
import { Col, Media, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

const ShoppingCartItem = ({ shoppingCartItem, onRemoveItem }) => {
  const { currency } = useContext(AppContext);
  const { id } = shoppingCartItem;

  const handleRemoveClick = async () => {
   onRemoveItem(id);
  }
  
  return (
    <Row noGutters className="align-items-center px-1 border-bottom border-200">
      <Col xs={8} className="py-3 px-2 px-md-3">
        <Media className="align-items-center">
          <Media body>
            <h5 className="fs-0">
              <Link className="text-900" to={`/jamtracks`}>
                { shoppingCartItem.product_info.sale_display }
              </Link>
            </h5>
            <div
              className="fs--2 fs-md--1 text-danger cursor-pointer"
              onClick={handleRemoveClick}
            >
              Remove
            </div>
          </Media>
        </Media>
      </Col>
      <Col xs={4} className="p-3">
        <Row className="align-items-center">
          <Col md={8} className="d-flex justify-content-end justify-content-md-center px-2 px-md-3 order-1 order-md-0">
            <div>
              { shoppingCartItem.quantity }
            </div>
          </Col>
          <Col md={4} className="text-right pl-0 pr-2 pr-md-3 order-0 order-md-1 mb-2 mb-md-0 text-600">
            {/* {currency}
            {calculateSale(price, sale) * quantity} */}
            {currency}
            { shoppingCartItem.product_info.total_price }
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

ShoppingCartItem.propTypes = {
  shoppingCartItem: PropTypes.object.isRequired,
  onRemoveItem: PropTypes.func.isRequired
};

export default ShoppingCartItem;
