import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useResponsive } from '@farfetch/react-context-responsive';
import JKInstrumentIcon from './JKInstrumentIcon';

const JKPersonInstrumentsList = ({ instruments, showIcons, showAll, toggleMoreDetails }) => {
  const proficiencies = {
    '1': 'Beginner',
    '2': 'Intermediate',
    '3': 'Expert'
  };

  const LIMIT = 4;

  const [instrumentsToShow, setInstrumentsToShow] = useState([]);

  const { greaterThan } = useResponsive();

  useEffect(() => {
    showAll || !greaterThan.xs ? setInstrumentsToShow(instruments) : setInstrumentsToShow(instruments.slice(0, LIMIT));
  }, [showAll]);

  return (
    <div data-testid="instrumentList">
      {instrumentsToShow &&
        instrumentsToShow.map(instrument => (
          <div key={instrument.instrument_id} className="text-nowrap mb-1 d-flex align-items-center" data-testid="instrument">
            <div className="mr-1" style={{ width: "15%"}}>
              <JKInstrumentIcon instrumentId={instrument.instrument_id} instrumentName={instrument.description} />
            </div>
            <div>
              <strong>{instrument.description}:</strong> {proficiencies[instrument.proficiency_level]}
            </div>
          </div>
        ))}
      {((!showAll && greaterThan.xs) || (showAll && !greaterThan.xs)) && instruments.length > LIMIT && (
        <a href="#/" onClick={e => toggleMoreDetails(e)}>
          More »
        </a>
      )}
    </div>
  );
};

JKPersonInstrumentsList.propTypes = {
  instruments: PropTypes.arrayOf(PropTypes.object).isRequired,
  showAll: PropTypes.bool,
  showIcons: PropTypes.bool,
  toggleMoreDetails: PropTypes.func
};

JKPersonInstrumentsList.defaltProps = {
  showAll: false,
  showIcons: true
};

export default JKPersonInstrumentsList;
