import React from 'react';
import Flex from '../../common/Flex';
import { Button, CardFooter } from 'reactstrap';
import { Link } from 'react-router-dom';

const ShoppingCartFooter = () => {

  return (
    <CardFooter tag={Flex} justify="end" className="bg-light">
      <Button tag={Link} color="primary" size="sm" to="/checkout" className="ml-2">
            Checkout
          </Button>
    </CardFooter>
  );
};

export default ShoppingCartFooter;
