import React, { useContext } from 'react';
import { Nav, NavItem, NavLink, UncontrolledTooltip, NavbarText } from 'reactstrap';
import ProfileDropdown from './JKProfileDropdown';
import NotificationDropdown from './JKNotificationDropdown';
import LangSwitch from './JKLangSwitch';
//import SettingsAnimatedIcon from './SettingsAnimatedIcon';
//import CartNotification from './CartNotification';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import AppContext from '../../context/Context';
//import classNames from 'classnames';
//import { navbarBreakPoint } from '../../config';
//import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../context/UserAuth';

const TopNavRightSideNavItem = () => {
  //const notifications = useSelector(state => state.notification.notifications.slice(0, 5));

  const { t } = useTranslation();

  const { isAuthenticated } = useAuth();

  //const { isTopNav, isCombo } = useContext(AppContext);
  return (
    <Nav navbar className="navbar-nav-icons ml-auto flex-row align-items-center">
      {/* <NavItem>
        <SettingsAnimatedIcon />
      </NavItem> */}

      <NavbarText className="d-none d-md-inline">{t('keep_jamkazam_improving', { ns: 'common' })}:</NavbarText>
      <NavItem className="d-none d-md-inline ml-1 mr-2">
        <a className="nav-link" href={`${process.env.REACT_APP_CLIENT_BASE_URL}/client#/account/subscription`} target="_blank">
          {t('subscribe', { ns: 'common' })}
        </a>
      </NavItem>

      {/* <LangSwitch /> */}
      {isAuthenticated ? (
        <>
          <NotificationDropdown />
          <ProfileDropdown />
        </>
      ) : (
        <NavItem className="d-none d-md-inline ml-6 mr-2">
          <NavLink tag={Link} to="/authentication/basic/login">
            {t('signin', { ns: 'auth' })}
          </NavLink>
        </NavItem>
      )}
    </Nav>
  );
};

export default TopNavRightSideNavItem;
