import { configureStore } from "@reduxjs/toolkit"
import textMessageReducer from "./features/textMessagesSlice"
import lobbyChatMessagesReducer from "./features/lobbyChatMessagesSlice"
import peopleReducer from "./features/peopleSlice"
import onlineMusicianReducer from "./features/onlineMusiciansSlice"
import sessionReducer from "./features/sessionsSlice"
import notificationReducer from './features/notificationSlice'
import latencyReducer from "./features/latencySlice"
import friendReducer from "./features/friendsSlice"
import sessionsHistoryReducer from "./features/sessionsHistorySlice"
import myJamTracksSlice from "./features/myJamTracksSlice"
import jamTrackSlice from "./features/jamTrackSlice"

export default configureStore({
  reducer: {
    textMessage: textMessageReducer,
    people: peopleReducer,
    notification: notificationReducer,
    session: sessionReducer, // this is the slice that holds the currently active sessions
    latency: latencyReducer,
    onlineMusician: onlineMusicianReducer,
    lobbyChat: lobbyChatMessagesReducer,
    friend: friendReducer,
    sessionsHistory: sessionsHistoryReducer, // this is the slice that holds the sessions history
    myJamTrack: myJamTracksSlice,
    jamTrack: jamTrackSlice
  }
})