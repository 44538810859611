import i18n from 'i18next';
import { initReactI18next } from "react-i18next";

import commonTranslationsEN from './locales/en/common.json'
import homeTranslationsEN from './locales/en/home.json'
import peopleTranslationsEN from './locales/en/people.json'
import friendsTranslationsEN from './locales/en/friends.json'
import authTranslationsEN from './locales/en/auth.json'
import sessTranslationsEN from './locales/en/sessions.json'
import unsubscribeTranslationsEN from './locales/en/unsubscribe.json'
import profileEN from './locales/en/profile.json'
import accountEN from './locales/en/account.json'
import affiliateEN from './locales/en/affiliate.json'
import jamTracksEn from './locales/en/jamtracks.json'
import checkoutEN from './locales/en/checkout.json'
import checkoutSuccessEN from './locales/en/checkout_success.json'

import commonTranslationsES from './locales/es/common.json'
import homeTranslationsES from './locales/es/home.json'
import peopleTranslationsES from './locales/es/people.json'
import friendsTranslationsES from './locales/es/friends.json'
import authTranslationsES from './locales/es/auth.json'
import sessTranslationsES from './locales/es/sessions.json'
import unsubscribeTranslationsES from './locales/es/unsubscribe.json'
import profileES from './locales/es/profile.json'
import accountES from './locales/es/account.json'
import affiliateES from './locales/es/affiliate.json'
import jamTracksEs from './locales/es/jamtracks.json'
import checkoutES from './locales/es/checkout.json'
import checkoutSuccessES from './locales/es/checkout_success.json'

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  resources: {
    en: {
      common: commonTranslationsEN,
      home: homeTranslationsEN,
      people: peopleTranslationsEN,
      auth: authTranslationsEN,
      sessions: sessTranslationsEN,
      unsubscribe: unsubscribeTranslationsEN,
      profile: profileEN,
      account: accountEN,
      friends: friendsTranslationsEN,
      affiliate: affiliateEN,
      jamtracks: jamTracksEn,
      checkout: checkoutEN,
      checkoutSuccess: checkoutSuccessEN
    },
    es: {
      common: commonTranslationsES,
      home: homeTranslationsES,
      people: peopleTranslationsES,
      auth: authTranslationsES,
      sessions: sessTranslationsES,
      unsubscribe: unsubscribeTranslationsES,
      profile: profileES,
      account: accountES,
      friends: friendsTranslationsES,
      affiliate: affiliateES,
      jamtracks: jamTracksEs,
      checkout: checkoutES,
      checkoutSuccess: checkoutSuccessES
    }
  },
  //ns: ['translations'],
  defaultNS: 'common'
});

i18n.languages = ['en', 'es'];

export default i18n;