import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import HttpsRedirect from 'react-https-redirect';
import App from './App';
import Main from './Main';
import './helpers/initFA';
import './i18n/config';

ReactDOM.render(
  <HttpsRedirect disabled={process.env.NODE_ENV === 'development'}>
    <Main>
      <App />
    </Main>
  </HttpsRedirect>,
  document.getElementById('main')
);
