import React from 'react';
import { useResponsive } from '@farfetch/react-context-responsive';
import { monthName } from '../../helpers/utils';
import { getDisplayName } from '../../helpers/subscriptionHelper';
import { Row, Col } from 'reactstrap';

const JKAffiliateEarning = ({ payment }) => {
  const { greaterThan } = useResponsive();
  return (
    <>
      {greaterThan.sm ? (
        <tr>
          <td>
            {monthName(payment.month - 1)} - {payment.year}
          </td>
          <td>
            {payment.subscriptions.map((subscription, index) => (
              <div key={index}>
                {getDisplayName(subscription.plan)} - {subscription.count}
              </div>
            ))}
          </td>
          <td>{payment.jamtracks_sold}</td>
          <td>${(payment.due_amount_in_cents / 100).toFixed(2)}</td>
        </tr>
      ) : (
        <>
          <Row className='mb-2'>
            <Col>
              {monthName(payment.month - 1)} {payment.year}
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <div>
                <strong><small>Subscriptions Sold:</small></strong>
              </div>
              {payment.subscriptions.map((subscription, index) => (
                <div key={index}>
                  <small>{getDisplayName(subscription.plan)} - {subscription.count}</small>
                </div>
              ))}
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col>
              <small>JamTracks Sold: {payment.jamtracks_sold}</small></Col>
          </Row>
          <Row>
            <Col><small>Earnings: ${(payment.due_amount_in_cents / 100).toFixed(2)}</small></Col>
          </Row>
        </>
      )}
    </>
  );
};

export default JKAffiliateEarning;
