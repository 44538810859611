import React from 'react';
import { Table, Button } from 'reactstrap';
import JKPerson from './JKPerson';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const JKPeopleList = ({ people, goNextPage, hasNext, isLoading }) => {
  const { t } = useTranslation();
  return (
    <>
      <Table striped bordered className="fs--1" data-testid="peopleListTable">
        <thead className="bg-200 text-900">
          <tr>
            <th scope="col">{t('person_attributes.name', { ns: 'people' })}</th>
            <th scope="col" style={{ minWidth: 250 }}>
              {t('person_attributes.about', { ns: 'people' })}
            </th>
            <th scope="col">{t('person_attributes.instruments', { ns: 'people' })}</th>
            <th scope="col">{t('person_attributes.genres', { ns: 'people' })}</th>
            <th scope="col">{t('actions', { ns: 'common' })}</th>
          </tr>
        </thead>
        <tbody className="list">
          {people.map(person => (
            <JKPerson person={person} viewMode="list" key={`jk-person-${person.id}`} />
          ))}
        </tbody>
      </Table>

      {hasNext && (
        <Button color="primary" outline={true} onClick={() => goNextPage()} disabled={isLoading} data-testid="paginate-next-page">
          {isLoading ? <span>Loading...</span> : <span>Load More</span>}
        </Button>
      )}
    </>
  );
};

JKPeopleList.propTypes = {
  people: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  goNextPage: PropTypes.func.isRequired,
  hasNext: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default JKPeopleList;
