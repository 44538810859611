import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Row, Col, Alert } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import { getInvoiceHistory } from '../../helpers/rest';
import { isIterableArray } from '../../helpers/utils';
import { useResponsive } from '@farfetch/react-context-responsive';
import PaymentHistoryList from '../payments/JKPaymentHistoryList';
import PaymentHistorySwiper from '../payments/JKPaymentHistorySwiper';
import { useIsMounted } from '../../hooks/useIsMounted';

const JKPaymentHistory = () => {
  const { t } = useTranslation('account');
  const [payments, setPayments] = useState([]);
  const LIMIT = 10;
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const { greaterThan } = useResponsive();
  const isMounted = useIsMounted();

  const fetchPayments = useCallback(async () => {
    const options = { limit: LIMIT, cursor: offset };
    try {
      setLoading(true);
      const response = await getInvoiceHistory(options);
      const data = await response.json();
      if (!isMounted.current) return;
      setPayments(prev => [...prev, ...data.entries]);
      setOffset(prev => prev + LIMIT);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [offset]);

  useEffect(() => {
    // fetch payments
    const options = { cursor: offset, limit: LIMIT };
    fetchPayments(options);
  }, []);

  const goToNextPage = async () => {
    const options = { cursor: offset, limit: LIMIT };
    await fetchPayments(options);
  };

  return (
    <Card>
      <FalconCardHeader title={t('payments.page_title')} titleClass="font-weight-bold" />
      <CardBody className="pt-3" style={{ backgroundColor: '#edf2f9' }}>
        <Row>
          <Col className="mb-2 col-md-9 col-lg-8 m-auto">
            {isIterableArray(payments) && payments.length ? (
              <>
                {greaterThan.sm ? (
                  <PaymentHistoryList payments={payments} goToNextPage={goToNextPage} loading={loading} />
                ) : (
                  <PaymentHistorySwiper payments={payments} goToNextPage={goToNextPage} />
                )}
              </>
            ) : (
              <Alert color="info" className="mb-0">
                {t('payments.no_payments')}
              </Alert>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default JKPaymentHistory;
