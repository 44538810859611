import React, { useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function JKInstrumentIcon({instrumentId, instrumentName}) {
  const InstrumentIcon = useCallback(
    () => {
      try {
        const file = require(`../../icons/instruments/icon_instrument_${instrumentId.replace(/\s+/g, '_')}.svg`);
        return <img width={25} height={25} src={file} alt={instrumentName} />
      } catch (error) {
        return <FontAwesomeIcon icon="music" size="sm" />
      }
    },
    [instrumentId],
  )
  
  return (
    InstrumentIcon()
  )
}

export default JKInstrumentIcon