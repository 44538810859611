import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getLobbyChatMessages, createLobbyChatMessage } from '../../helpers/rest';

export const fetchLobbyChatMessages = createAsyncThunk('chatMessage/fetchLobbyChatMessages', async (options, thunkAPI) => {
  const params = { ...options, type: 'CHAT_MESSAGE', channel: 'lobby'}
  const response = await getLobbyChatMessages(params);
  return response.json();
});

export const postNewChatMessage = createAsyncThunk('chatMessage/postNewChatMessage', async (options, thunkAPI) => {
  const response = await createLobbyChatMessage(options);
  return response.json();
});

const chatMessagesSlice = createSlice({
  name: 'chatMessages',
  initialState: {
    records: {
      messages: [],
      next: null
    },
    status: 'idel',
    error: null,
    create_status: 'idel',
  },
  reducers: {
    addMessage(state, action) {
      state.messages.push(action.payload);
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchLobbyChatMessages.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchLobbyChatMessages.fulfilled, (state, action) => {
        const lastOnly = action.meta.arg.lastOnly;
        state.records = { 
          next: state.records.next === null && lastOnly? null : action.payload.next, 
          messages: action.payload.chats.map(m => ({...m, status: 'delivered'})).sort((a, b) => {
            return new Date(a.created_at) - new Date(b.created_at);
          })
        };
        state.status = 'succeeded';
      })
      // .addCase(fetchLobbyChatMessages.fulfilled, (state, action) => {
      //   const lastOnly = action.meta.arg.lastOnly;
      //   const currentChatMessageIds = state.records.messages.map(m => m.id);
      //   const newMessages = action.payload.chats.filter(m => !currentChatMessageIds.includes(m.id));
      //   state.records = { 
      //     next: state.records.next === null && lastOnly? null : action.payload.next, 
      //     messages: state.records.messages.concat(newMessages).map(m => ({...m, status: 'delivered'})).sort((a, b) => {
      //       return new Date(a.created_at) - new Date(b.created_at);
      //     })
      //   };
      //   state.status = 'succeeded';
      // })
      .addCase(fetchLobbyChatMessages.rejected, (state, action) => {
        state.error = action.payload;
        state.status = 'failed';
      })
      .addCase(postNewChatMessage.pending, (state, action) => {
        state.create_status = 'loading';
      })
      .addCase(postNewChatMessage.fulfilled, (state, action) => {
        state.create_status = 'succeeded';
      })
      .addCase(postNewChatMessage.rejected, (state, action) => {
        state.create_status = 'failed';
      })
  }
});

export const { addMessage } = chatMessagesSlice.actions;

export default chatMessagesSlice.reducer;
