import React, { useState, useEffect } from 'react';
import { Alert, Card, CardBody, Col, Row } from 'reactstrap';
import JKNotification from '../notification/JKNotification';
import FalconCardHeader from '../common/FalconCardHeader';
import Loader from '../common/Loader';
import { isIterableArray } from '../../helpers/utils';

import { useAuth } from '../../context/UserAuth';
import useNotifications from '../../hooks/useNotifications';

const JKNotifications = () => {
  const { currentUser, isAuthenticated } = useAuth();
  const { 
    notifications, 
    offset, 
    setOffset, 
    next, 
    unread_total, 
    loadNotifications, 
    notificationStatus: loadingState
  } = useNotifications(currentUser);

  useEffect(() => {
    if(isAuthenticated)
      loadNotifications();
  }, [currentUser]);

  useEffect(() => {
    if (isAuthenticated && offset > 0 && next !== null) {
      loadNotifications();
    }
  }, [offset]);

  useEffect(() => {
    const onscroll = () => {
      console.log("scrolling", window.scrollY, window.innerHeight, document.body.scrollHeight);
      const scrolledTo = window.scrollY + window.innerHeight;
      const isReachBottom = document.body.scrollHeight === scrolledTo;
      if (isReachBottom) {
        setOffset(offset + 1);
      }
    };
    window.addEventListener("scroll", onscroll);
    return () => {
      window.removeEventListener("scroll", onscroll);
    };
  }, []);

  return (
    <Card className="h-100">
      <FalconCardHeader title="Your Notifications">
        {/* <div className="fs--1">
          <Link className="text-sans-serif" to="#!" onClick={markAsRead}>
            Mark all as read
          </Link>
        </div> */}
      </FalconCardHeader>
      <CardBody className="p-0">
        {loadingState === 'loading' ? (
          <Loader />
        ) : isIterableArray(notifications) ? (
          notifications.map(notification => (
            <JKNotification notification={notification} key={notification.notification_id} />
          ))
        ) : (
          <Row className="p-card">
            <Col>
              <Alert color="info" className="mb-0">
                No notifications found!
              </Alert>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default JKNotifications;
