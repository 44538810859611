import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {getNotifications, deleteNotification} from '../../helpers/rest'
const initialState = {
  notifications: [],
  next: null,
  unread_total: 0,
  status: 'idel',
  error: null
}

export const fetchNotifications = createAsyncThunk(
  'notifications/fetch',
  async (options) => {
    const { userId, ...rest } = options
    const response  = await getNotifications(userId, rest)
    return response.json()
  }
)

export const removeNotification = createAsyncThunk(
  'notifications/delete',
  async (options) => {
    const {userId, notificationId} = options
    const response = await deleteNotification(userId, notificationId)
    if (response.status === 204) return {}
    return response.json()
  }
)

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    add: (state, action) => {
      if(!action.payload.read_at){
        state.unread_total += 1
      }
      state.notifications.unshift(action.payload)
    },
    modify: state => {},
    remove: (state, action) => {
      if(!action.payload.read_at){
        state.unread_total -= 1
      }
      state.notifications = state.notifications.filter(n => n.id !== action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchNotifications.pending, (state, action) => {
      state.status = 'loading'
    })
    .addCase(fetchNotifications.fulfilled, (state, action) => {
      const records = new Set([...state.notifications, ...action.payload.notifications]);
      const unique = [];
      records.map(x => unique.filter(a => a.notification_id === x.notification_id).length > 0 ? null : unique.push(x))
      state.notifications = unique
      state.next = action.payload.next
      state.unread_total = action.payload.unread_total
      state.status = 'succeeded'
    })
    .addCase(fetchNotifications.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    })
    .addCase(removeNotification.fulfilled, (state, action) => {
      const notificationId = action.meta.arg.notificationId;
      state.notifications = state.notifications.filter(n => n.notification_id !== notificationId)
    })
  }
})

export const { add } = notificationsSlice.actions;
export default notificationsSlice.reducer;