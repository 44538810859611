import React, { useState, useEffect } from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import { useTranslation } from 'react-i18next';
import JKJamTracksAutoComplete from './JKJamTracksAutoComplete';
import { getJamTracks, getJamTrackArtists, autocompleteJamTracks } from '../../helpers/rest';
import JKJamTrackArtists from './JKJamTrackArtists';
import JKJamTracksList from './JKJamTracksList';

const JKJamTracksFilter = () => {
  const { t } = useTranslation('jamtracks');
  const [jamTracks, setJamTracks] = useState([]);
  const [artists, setArtists] = useState([]);
  const [selected, setSelected] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [showArtists, setShowArtists] = useState(false);
  const [nextOffset, setNextOffset] = useState(null);
  const [autoCompleteInputValue, setAutoCompleteInputValue] = useState('');
  const [page, setPage] = useState(1);
  const PER_PAGE = 10;

  useEffect(() => {
    if (selected) {
      setSearchTerm(selected.type === 'artist' ? selected.original_artist : selected.name);
    }
  }, [selected]);

  const queryOptions = selected => {
    const options = {
      per_page: PER_PAGE,
      page: page
    };

    if (typeof selected === 'string') {
      options.search = selected;
      return options;
    }

    if (selected.type === 'artist') {
      options.artist = selected.original_artist;
    } else {
      options.song = selected.name;
    }

    return options;
  };

  const handleOnSelect = async (selected) => {
    setPage(1);
    setArtists([]);
    setJamTracks([]);
    setSearchTerm('');
    setShowArtists(false);
    setSelected(selected);
    const params = queryOptions(selected);
    await fetchJamTracks(params);
  };

  const handleOnEnter = async(queryStr) => {
    setPage(1);
    setArtists([]);
    setJamTracks([]);
    setSelected(x => null);
    setSearchTerm(queryStr);
    fetchArtists(queryStr);
    const params = queryOptions(queryStr);
    console.log('handleOnEnter _params', params, selected);
    await fetchJamTracks(params);
  };

  const handleOnSelectArtist = async(artist) => {
    setPage(1);
    const selectedOpt = {
      type: 'artist',
      original_artist: artist.original_artist
    };
    setShowDropdown(false);
    setAutoCompleteInputValue('');
    await handleOnSelect(selectedOpt);
  };

  const handleOnNextJamTracksPage = async () => {
    const currentQuery = selected ? selected : searchTerm;
    const params = queryOptions(currentQuery);
    await fetchJamTracks(params);
  }

  // const fetchJamTracks = options => {
  //   getJamTracks(options)
  //     .then(resp => {
  //       return resp.json();
  //     })
  //     .then(data => {
  //       console.log('jamtracks', data);
  //       setJamTracks(prev => [...prev, ...data.jamtracks]);
  //       setNextOffset(data.next);
  //       setPage(page => page + 1);
  //     })
  //     .catch(error => {
  //       console.error('error', error);
  //     });
  // };


  const fetchJamTracks = async(options) => {
    try {
      console.log('fetchJamTracks', options);
      const resp = await getJamTracks(options);
      const data = await resp.json();
      console.log('jamtracks', data);
      setJamTracks(prev => [...prev, ...data.jamtracks]);
      setNextOffset(data.next);
     
    } catch (error) {
      console.error('error', error);
    }
  }


  const fetchArtists = query => {
    const options = {
      limit: 100
    };
    options.artist_search = query;

    getJamTrackArtists(options)
      .then(resp => {
        return resp.json();
      })
      .then(data => {
        console.log('artists', data);
        setArtists(data.artists);
        setShowArtists(true);
      })
      .catch(error => {
        console.error('error', error);
      });
  };

  return (
    <Card>
      <FalconCardHeader title={t('search.page_title')} titleClass="font-weight-bold" />
      <CardBody className="pt-3">
        <Row>
          <Col>
            <JKJamTracksAutoComplete
              fetchFunc={autocompleteJamTracks}
              onSelect={handleOnSelect}
              onEnter={handleOnEnter}
              showDropdown={showDropdown}
              setShowDropdown={setShowDropdown}
              inputValue={autoCompleteInputValue}
              setInputValue={setAutoCompleteInputValue}
              inputPlaceholder={t('search.search_input.placeholder')}
            />
          </Col>
          <Col className="text-right">
            <span>
              Download JamTracks catalog as a{' '}
              <a data-testid="download-pdf" href="https://s3.amazonaws.com/jamkazam-public/public/lists/all-jamkazam-jamtracks.pdf">
                <strong>PDF file</strong>
              </a>
            </span>
          </Col>
        </Row>

        <div className="mb-3">
          <JKJamTrackArtists
            artists={artists}
            searchTerm={searchTerm}
            onSelect={handleOnSelectArtist}
            showArtists={showArtists}
          />
        </div>
        <JKJamTracksList selectedType={selected?.type} searchTerm={searchTerm} jamTracks={jamTracks} nextOffset={nextOffset} onNextPage={handleOnNextJamTracksPage} />
      </CardBody>
    </Card>
  );
};

export default JKJamTracksFilter;
