import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardText, CardTitle  } from 'reactstrap';
import { useTranslation } from "react-i18next";
import { useBrowserQuery } from '../../context/BrowserQuery';
import { useHistory } from "react-router-dom";


const unsubscribeFromNewUsersWeeklyEmail = (token) => {
  
  const baseUrl = process.env.REACT_APP_LEGACY_BASE_URL

  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}/unsubscribe_user_match/${token}`, 
      { method: 'POST' }
    ).then(response => {
      if (response.ok) {
        resolve(response);
      } else {
        reject(response)
      }
    })
  })
}

function JKUnsubscribe() {
  const {t} = useTranslation()
  const queryObj = useBrowserQuery();
  const history = useHistory()
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    const token = queryObj.get('tok')
    if(token){
      unsubscribeFromNewUsersWeeklyEmail(token)
      .then((resp) => {
        if(resp.ok){
          setSuccess(true)
        }
      })
      .catch(error => console.error(error))
    }else{
      history.push('/')
    }
  }, [])
  
  return (
  
  
    <Card color={ success ? 'success' : 'light' } style={{ width: '25rem', margin: '2rem auto' }}>
      <CardBody>
        <CardTitle className="mb-2">Unsubscribe From Weekly Email</CardTitle>
        <CardText>
          {
            success? 
            'You have successfully unsubscribed from weekly emails on newly joined musicians having low internet latency to you.' : 
            'Unsubscribing...'
          }
        </CardText>
      </CardBody>
    </Card>
  
  )
}

export default JKUnsubscribe
