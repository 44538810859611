import React from 'react';
import { Table } from 'reactstrap';
import JKSession from './JKSession';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function JKSessionList({ sessions }) {
  const { t } = useTranslation();
  return (
    <Table striped bordered className="fs--1" data-testid="sessionsListTable">
      <thead className="bg-200 text-900">
        <tr>
          <th width="35%" scope="col">{t('list.header.session', { ns: 'sessions' })}</th>
          <th width="15%" scope="col" style={{ minWidth: 250 }}>
            {t('list.header.musicians', { ns: 'sessions' })}
          </th>
          <th scope="col" className='text-center'>{t('list.header.latency', { ns: 'sessions' })}</th>
          <th scope="col">{t('list.header.instruments', { ns: 'sessions' })}</th>
          <th scope="col" className="text-center">{t('actions', { ns: 'common' })}</th>
        </tr>
      </thead>
      <tbody className="list">
        {sessions.map(session => (
          <JKSession key={session.id} session={session} />
        ))}
      </tbody>
    </Table>
  );
}

JKSessionList.propTypes = { sessions: PropTypes.array.isRequired };

export default JKSessionList;
