import { useEffect } from 'react';
const useScript = (src, onloadHandler = () => {}) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = src;
    script.defer = false;
    document.body.appendChild(script);
    script.onload = onloadHandler;
    return () => {
      document.body.removeChild(script);
    };
  }, [src, onloadHandler]);
};
export default useScript;
