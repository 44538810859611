import React from 'react'
import { useLocation } from "react-router-dom";

const BrowserQueryContext = React.createContext(null)

export const BrowserQueryProvider = ({children}) => {

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const queryObj = useQuery();

  return(
    <BrowserQueryContext.Provider value={ queryObj }>
      { children }
    </BrowserQueryContext.Provider>
  )
}

export const useBrowserQuery = () => React.useContext(BrowserQueryContext)