import React, { useEffect, useState } from 'react';
import * as filestack from 'filestack-js';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import JKModalDialog from '../common/JKModalDialog';
import JKProfileAvatar from './JKProfileAvatar';
import { useAuth } from '../../context/UserAuth';
import { deleteAvatar, updateAvatar } from '../../helpers/rest';
import { toast } from 'react-toastify';
//import useUserProfile from '../../hooks/useUserProfile';
import { useAppData } from '../../context/AppDataContext';
import { set } from 'react-hook-form';

const JKProfileAvatarUpload = ({ show, toggle }) => {
  const { t } = useTranslation('profile');
  const { currentUser } = useAuth();
  // const { photoUrl } = useUserProfile(currentUser);
  const { appData, setAppData } = useAppData();
  const { currentUserPhotoUrl } = appData;
  const [isProcessing, setIsProsessing] = useState(false);

  const openFilePicker = () => {
    const client = filestack.init(window.gon.fp_apikey);
    const options = {
      accept: ['image/*'],
      fromSources: ['local_file_system', 'facebook'],
      maxFiles: 1,
      imageMax: [88, 88],
      imageMin: [200, 200],
      transformations: {
        crop: {
          force: true // Ensures that the crop (and circle) is applied
        }
      },
      storeTo: {
        location: 's3',
        path: `${window.gon.fp_upload_dir}/${currentUser.id}/`
      },
      onFileUploadStarted: () => {
        setIsProsessing(true);
      },
      onFileUploadFailed: () => {
        setIsProsessing(false);
      },
      onUploadDone: res => {
        console.log('onUploadDone', res);
        // Update the user photo_url
        // updateUserPhotoUrl(currentUser.id, res.filesUploaded[0].url);
        if (res.filesUploaded.length > 0) {
          const opts = {
            id: currentUser.id,
            url: res.filesUploaded[0].url,
            // cropped_selection: null,
          }
          // if(res.filesUploaded[0].cropped){
          //   opts['crop_selection'] = {
          //     x: res.filesUploaded[0].cropped.cropArea.position[0],
          //     y: res.filesUploaded[0].cropped.cropArea.position[1],
          //     w: res.filesUploaded[0].cropped.cropArea.size[0],
          //     h: res.filesUploaded[0].cropped.cropArea.size[1],
          //   };
          // }
          updateAvatar(opts).then(response => {
            if (response.ok) {
              return response.json();
            }
          }).then(data => {
            console.log('photo upload success', data);
            setAppData({
              ...appData,
              currentUserPhotoUrl: data.v2_photo_url
            });
            toast.success('Success! Your avatar has been updated.');
            setIsProsessing(false);
          }).catch(error => {
            console.error(error);
            toast.error('An error encountered when updating avatar.');
            setIsProsessing(false);
          });
        }
      }
    };
    client.picker(options).open();
  };

  const deleteProfileAvatar = () => {
    setIsProsessing(true);
    deleteAvatar(currentUser.id).then(response => {
      if (response.ok) {
        setAppData({
          ...appData,
          currentUserPhotoUrl: null
        });
        toast.success('Success! Your avatar has been deleted.');
      }
    }).catch(error => {
      console.error(error);
      toast.error('An error encountered when deleting avatar.');
    }).finally(() => {
      setIsProsessing(false);
    });
  }

  return (
    <JKModalDialog show={show} onToggle={toggle} title={t('photo_modal.title', { ns: 'profile' })} showFooter={true}>
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-center">
          { <JKProfileAvatar src={currentUserPhotoUrl} size="5xl" /> }
        </div>
     
        <div className="d-flex justify-content-center mt-2">
          <Button color="primary" className="ml-2" onClick={openFilePicker} disabled={isProcessing}>
            {t('photo_modal.upload', { ns: 'profile' })}
          </Button>
          <Button color="secondary" outline className="ml-2" onClick={deleteProfileAvatar} disabled={isProcessing}>
            {t('photo_modal.delete', { ns: 'profile' })}
          </Button>
        </div>
      </div>
    </JKModalDialog>
  );
};

export default JKProfileAvatarUpload;
