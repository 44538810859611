import React from 'react';
import { Col, Row } from 'reactstrap';
import { version } from '../../config';
import { useTranslation } from 'react-i18next';
const JKFooter = () => {
  const {t} = useTranslation();

  return (
    <footer>
      <Row noGutters className="justify-content-between text-center fs--1 mt-4 mb-3">
        <Col sm="auto">
          <p className="mb-0 text-600">
            Copyright &copy; {new Date().getFullYear()} JamKazam, Inc. All Rights Reserved.
            <span className="ml-2 mb-0 text-600">(v{version})</span>
          </p>
        </Col>

        <Col sm="auto">
          <p className="mb-0 text-600 text-lowercase">
            {' '}
            <a href={`${process.env.REACT_APP_CLIENT_BASE_URL}/corp/about`} target="_blank">
              {t('navigation.about', {ns: 'common'})}
            </a>{' '}
            |{' '}
            <a href={`${process.env.REACT_APP_CLIENT_BASE_URL}/corp/contact`} target="_blank">
            {t('navigation.contact', {ns: 'common'})}
            </a>{' '}
            |{' '}
            <a href={`${process.env.REACT_APP_CLIENT_BASE_URL}/corp/privacy`} target="_blank">
            {t('navigation.privacy', {ns: 'common'})}
            </a>{' '}
            |{' '}
            <a href={`${process.env.REACT_APP_CLIENT_BASE_URL}/corp/terms`} target="_blank">
            {t('navigation.terms', {ns: 'common'})}
            </a>
            |{' '}
            <a href={`${process.env.REACT_APP_CLIENT_BASE_URL}/help_desk`} target="_blank">
            {t('navigation.help', {ns: 'common'})}
            </a>
          </p>
         
        </Col>
      </Row>
    </footer>
  );
};

export default JKFooter;
