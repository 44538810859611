import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import { getPeopleIndex, getLobbyUsers } from "../../helpers/rest";

const initialState = {
  musicians: [],
  status: 'idle',
  error: null,
}

export const fetchOnlineMusicians = createAsyncThunk(
  'onlineMusician/fetchMusicians',
  async (options, thunkAPI) => {
    //const response = await getPeopleIndex(options)
    const response = await getLobbyUsers(options)
    return response.json()
  }
)

export const onlineMusiciansSlice = createSlice({
  name: 'onlineMusicians',
  initialState,
  reducers: {
    add: (state, action) => {
      state.musicians.push(action.payload)
    },
    remove: (state, action) => {
      state.musicians = state.musicians.filter(musician => musician.id !== action.payload.id)
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchOnlineMusicians.pending, (state, action) => {
      state.status = 'loading'
    })
    .addCase(fetchOnlineMusicians.fulfilled, (state, action) => {
      console.log('fetchOnlineMusicians.fulfilled', action.payload)
      state.status = 'succeeded'
      state.musicians = action.payload
      // if (action.payload && action.payload.length > 0) {
      //   const difference = state.musicians.filter((element) => !action.payload.includes(element));
      //   if (difference.length > 0) {
      //     state.musicians = action.payload
      //   }
      // }
    })
    .addCase(fetchOnlineMusicians.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })
  }
})

export default onlineMusiciansSlice.reducer