import React, { useState, useRef } from 'react';
import { Input, InputGroup, InputGroupText, ListGroup, ListGroupItem, Row, Col, Button } from 'reactstrap';
import JKSelectFriendsModal from './JKSelectFriendsModal';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';
import JKProfileAvatar from '../profile/JKProfileAvatar';
import { useResponsive } from '@farfetch/react-context-responsive';

function JKFriendsAutoComplete({ friends, onSelect }) {
  const [filteredFriends, setFilteredFriends] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [showModal, setShowModal] = useState(false);
  const inputRef = useRef(null);
  const { t } = useTranslation();
  const MIN_FILTER_SIZE = 3;
  const { greaterThan } = useResponsive();

  const handleInputChange = e => {
    const val = e.target.value;
    setInputValue(val);
    if (val && val.length >= MIN_FILTER_SIZE) {
      const filtered = friends.filter(
        friend =>
          friend.first_name.toLowerCase().includes(val.toLowerCase()) ||
          friend.last_name.toLowerCase().includes(val.toLowerCase())
      );
      setFilteredFriends(filtered);
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };

  const handleOnClick = friend => {
    onSelect([friend]);
    handleAfterSelect();
  };

  const handleAfterSelect = () => {
    setShowDropdown(false);
    inputRef.current.focus();
    setInputValue('');
  };

  const handleOnKeyDown = event => {
    if (event.key !== undefined) {
      if (event.key === 'Enter') {
        const first = filteredFriends[0];
        onSelect([first]);
        handleAfterSelect();
      } else if (event.key === 'ArrowDown') {
        console.log(event.target);
      }
    }
  };

  const highlight = event => {
    event.target.classList.add('bg-light');
  };

  const unhighlight = event => {
    event.target.classList.remove('bg-light');
  };

  const onSubmitFriendsModal = selectedFriends => {
    onSelect(selectedFriends);
  };

  const toggleVisibility = isVisible => {
    setShowModal(isVisible);
  };

  const chooseFriendsWrapperClass = greaterThan.sm ? 'text-right' : 'text-left mt-2';

  return (
    <div>
      <Row className="mb-2">
        <Col md={8}>
          <InputGroup>
            <InputGroupText>
              <FontAwesomeIcon icon="search" transform="shrink-4 down-1" />
            </InputGroupText>

            <Input
              onChange={handleInputChange}
              onKeyDown={handleOnKeyDown}
              value={inputValue}
              innerRef={inputRef}
              placeholder={t('new.invitations_filter_placeholder', { ns: 'sessions' })}
              data-testid="autocomplete-text"
            />
          </InputGroup>
        </Col>
        <Col md={4} className={chooseFriendsWrapperClass}>
          <Button
            variant="outline-info"
            outline
            onClick={() => setShowModal(!showModal)}
            data-testid="btn-choose-friends"
          >
            {t('new.choose_friends', { ns: 'sessions' })}
          </Button>
        </Col>
      </Row>

      <ListGroup className={showDropdown ? 'd-block' : 'd-none'} data-testid="autocomplete-list">
        {filteredFriends.map(f => (
          <ListGroupItem key={f.id} onMouseOver={highlight} onMouseOut={unhighlight} onClick={() => handleOnClick(f)}>
            <div style={{ display: "grid", gridTemplateColumns: "30px auto", columnGap: "5px", alignContent: "flex-start"}}>
              <div>
                <JKProfileAvatar src={f.photo_url} size="m" /> 
              </div>
              <div className='ml-2'>
                {f.first_name} {f.last_name}
              </div>
            </div>
          </ListGroupItem>
        ))}
      </ListGroup>

      <JKSelectFriendsModal
        friends={friends}
        show={showModal}
        toggleVisibility={toggleVisibility}
        onSubmit={onSubmitFriendsModal}
      />
    </div>
  );
}

JKFriendsAutoComplete.propTypes = {
  friends: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      first_name: PropTypes.string,
      last_name: PropTypes.string
    })
  ),
  onSelect: PropTypes.func.isRequired
};

export default JKFriendsAutoComplete;
