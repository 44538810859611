import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { addJamtrackToShoppingCart } from '../../helpers/rest';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../context/UserAuth';
import { toast } from 'react-toastify';
import { useShoppingCart } from '../../hooks/useShoppingCart';

const JKJamTrackPurchaseButton = ({ jamTrack }) => {
  const history = useHistory();
  const { currentUser } = useAuth();
  const { addCartItem } = useShoppingCart();

  const addToCart = async () => {
    const options = {
      id: jamTrack.id,
      variant: 'full'
    };
    if (await addCartItem(options)) {
      toast.success('JamTrack added to cart');
      history.push('/shopping-cart');
    } else {
      console.log('Add to Cart Error');
      toast.error('Error adding to cart');
    }
  };

  return (
    <>
      {jamTrack.purchaed ? (
        <Button color="light" size="sm" className="mr-1">
          Purchased
        </Button>
      ) : jamTrack.is_free && currentUser && currentUser.show_free_jamtrack ? (
        <Button color="primary" onClick={addToCart} size="sm" className="mr-1">
          Get It Free!
        </Button>
      ) : jamTrack.added_cart ? (
        <>
          <Button color="light" size="sm" className="mr-1">
            Already in Cart
          </Button>
        </>
      ) : (
        <>
          <div className="fs-1">$ {jamTrack.download_price}</div>
          <Button color="primary" size="sm" className="mr-1" onClick={addToCart}>
            Add to Cart
          </Button>
        </>
      )}
    </>
  );
};

JKJamTrackPurchaseButton.propTypes = {
  jamTrack: PropTypes.object.isRequired
};

export default JKJamTrackPurchaseButton;
