import React from 'react'
import TimeAgo from "react-timeago";

function JKLastActiveAgo({timestamp}) {
  return (
  
      <TimeAgo date={new Date(timestamp * 1000)} />
  )
}

export default JKLastActiveAgo
