import React, { useEffect } from 'react';
import { Alert, Col, Row, Card, CardBody } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSessions } from '../../store/features/sessionsSlice';
import { isIterableArray } from '../../helpers/utils';
import { useResponsive } from '@farfetch/react-context-responsive';
import JKModalDialog from '../common/JKModalDialog';
import { useNativeApp } from '../../context/NativeAppContext';

import JKSessionSwiper from '../sessions/JKSessionSwiper';
import JKSessionList from '../sessions/JKSessionList';
import Loader from '../common/Loader';

function JKMusicSessions() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sessions = useSelector(state => state.session.sessions);
  const loadingStatus = useSelector(state => state.session.status);
  const { greaterThan } = useResponsive();
  const { nativeAppUnavailable, setNativeAppUnavailable } = useNativeApp();

  useEffect(() => {
    dispatch(fetchSessions());
  }, []);

  const toggleAppUnavilableModel = () => {
    setNativeAppUnavailable(!nativeAppUnavailable);
  };

  return (
    <>
      <Card>
        <FalconCardHeader title={t('list.page_title', { ns: 'sessions' })} titleClass="font-weight-bold" />
        <CardBody className="pt-0">
          {loadingStatus === 'loading' && sessions.length === 0 ? (
            <Loader />
          ) : isIterableArray(sessions) ? (
            <>
              {greaterThan.sm ? (
                <Row className="mb-3 justify-content-between d-none d-md-block">
                  <div className="table-responsive-xl px-2">
                    <JKSessionList sessions={sessions} />
                  </div>
                </Row>
              ) : (
                <Row className="swiper-container d-block d-md-none" data-testid="sessionsSwiper">
                  <JKSessionSwiper sessions={sessions} />
                </Row>
              )}
            </>
          ) : (
            <Row className="p-card">
              <Col>
                <Alert color="info" className="mb-0">
                  {t('no_records', { ns: 'common' })}
                </Alert>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
      <JKModalDialog
        show={nativeAppUnavailable}
        onToggle={toggleAppUnavilableModel}
        title={t('modals.native_app_unavailable.title', { ns: 'common' })}
        data-testid="native-app-unavailable"
      >
        <p>{t('modals.native_app_unavailable.body', { ns: 'common' })}</p>
        <div className="d-flex flex-row">
          <a
            href="https://www.jamkazam.com/downloads"
            onClick={toggleAppUnavilableModel}
            target="_blank"
            className="btn btn-primary mr-2"
          >
            Download JamKazam App
          </a>
          <a
            href="https://www.jamkazam.com/help_desk"
            onClick={toggleAppUnavilableModel}
            target="_blank"
            className="btn btn-light"
          >
            Get Help
          </a>
        </div>
      </JKModalDialog>
    </>
  );
}

export default JKMusicSessions;
