import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

function JKMoreDetailsButton({ toggleMoreDetails, cssClasses, children}) {
  const { t } = useTranslation();
  return (
    <a href="/#" onClick={toggleMoreDetails} data-testid="btnMore">
      <span
        className={cssClasses}
        data-bs-toggle="tooltip"
        title={t('view_profile', { ns: 'people' })}
      >
        {  children }
      </span>
    </a>
  );
}

export default JKMoreDetailsButton;
