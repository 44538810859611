import React, { useEffect, useState, useRef } from 'react';
import { Card, CardHeader, CardBody, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { formatDateShort } from '../../helpers/utils';
import { planNameWithCycle, displayTime } from '../../helpers/subscriptionHelper';

function JKSubscriptionPlaytime({ userPlan, getDisplayName }) {
  const { t } = useTranslation('account');

  const [explanation, setExplanation] = useState('');
  const [warning, setWarning] = useState('');
  const [billingAddendum, setBillingAddendum] = useState(''); // [TODO: addendum to the warning message about billing, if needed]
  const showPaymentInfoRef = useRef(false);

  // const displayTime = until_time => {
  //   if (until_time < 0) return 'no time';

  //   const untilTime = getTimeRemaining(until_time * 1000);

  //   let timeString = '';
  //   if (untilTime.days !== 0) timeString += `${untilTime.days} days, `;
  //   if (untilTime.hours !== 0 || timeString.length > 0) timeString += `${untilTime.hours} hours, `;
  //   if (untilTime.minutes !== 0 || timeString.length > 0) timeString += `${untilTime.minutes} minutes `;
  //   if (timeString === '') timeString = 'now!';

  //   return timeString;
  // };

  // const getTimeRemaining = t => {
  //   if (t < 0) t = -t;

  //   const seconds = Math.floor((t / 1000) % 60);
  //   const minutes = Math.floor((t / 1000 / 60) % 60);
  //   const hours = Math.floor((t / (1000 * 60 * 60)) % 24);
  //   const days = Math.floor(t / (1000 * 60 * 60 * 24));

  //   return {
  //     total: t,
  //     days: days,
  //     hours: hours,
  //     minutes: minutes,
  //     seconds: seconds
  //   };
  // };

  // const getDisplayNamePrice = planCode => {
  //   if (planCode == '') {
  //     planCode = null;
  //   }
  //   const plan = window.gon.global.subscription_codes.find(plan => plan.id === planCode);
  //   if (plan) {
  //     return plan.price;
  //   }
  //   return `Unknown plan code=${planCode}`;
  // };

  // const getDisplayCycle = planCode => {
  //   if (planCode == '') {
  //     planCode = null;
  //   }
  //   for (const subscriptionCode of window.gon.global.subscription_codes) {
  //     if (planCode === subscriptionCode.id) {
  //       if (subscriptionCode.cycle === 'year') {
  //         return 'annual';
  //       } else {
  //         return subscriptionCode.cycle + 'ly';
  //       }
  //     }
  //   }
  //   return `Unknown plan code=${planCode}`;
  // };

  // const planNameWithCycle = planCode => {
  //   return getDisplayName(planCode) + ' (' + getDisplayCycle(planCode) + ')';
  // };

  useEffect(() => {
    if (userPlan) {
      let expl, note, warning, billingAddendumTxt;
      const adminOverride = userPlan.admin_override_plan_code;
      const inTrail = userPlan.in_trail;
      const hasBillingInfo = userPlan.has_billing_info;
      const effectivePlanName = planNameWithCycle(userPlan.plan_code);
      const desiredPlanCode = userPlan.desired_plan_code;
      const desiredPlanName = planNameWithCycle(desiredPlanCode);
      const hasPendingSubscription = userPlan.subscription?.pending_subscription;
      const canceledSubscription = userPlan.subscription?.remaining_billing_cycles === 0;

      if (adminOverride) {
        expl = `You have a <strong>${effectivePlanName}</strong> account until your gifted plan ends ${formatDateShort(
          userPlan.admin_override_ends_at
        )}.`;
      } else if (inTrail) {
        if (desiredPlanCode) {
          if (hasBillingInfo) {
            note = `Billing starts for the <strong>${desiredPlanName}</strong> plan after the trial ends.`;
          } else {
            warning = `You will drop to the <strong>free plan</strong> after the trial ends because you have not yet entered payment info.`;
            showPaymentInfoRef.current = true;
          }
        } else {
          if (hasBillingInfo) {
            warning = `You will drop to the <strong>free plan</strong> after the trial ends because you have not selected a plan.`;
          } else {
            warning = `You will drop to the <strong>free plan</strong> after the trial ends because you have not yet entered payment info or selected a plan.`;
            showPaymentInfoRef.current = true;
          }
        }
        expl = `You have a <strong>${effectivePlanName}</strong> account until your trial ends <strong>${formatDateShort(
          userPlan.trial_ends_at
        )}</strong>. ${note}`;
      } else {
        // NOT admin override and NOT in trial
        if (desiredPlanCode && !userPlan.plan_code && !hasBillingInfo) {
          expl = `You have successfully upgraded your plan to the <strong>${desiredPlanName}</strong> level, thank you!`;
          warning = `For this plan to take effect, you must provide a payment method (e.g. a credit card), for the monthly subscription charge. Please click the Update Payment Method button to do this now.`;
          //show_payment_info = true
          showPaymentInfoRef.current = true;
        } else {
          if (desiredPlanCode) {
            if (!hasBillingInfo) {
              //show_payment_info = true
              showPaymentInfoRef.current = true;
              expl = `You have successfully upgraded your plan to the <strong>${desiredPlanName}</strong> level, thank you.`;
              warning = `However, you must provide a payment method (e.g. a credit card), for the monthly subscription charge. Please click the Update Payment Method button to do this now.`;
            } else {
              expl = `You are currently on the <strong>${effectivePlanName}</strong> level, thank you!`;
            }
          } else {
            //free plan situation - not much to go on about
            expl = `You are currently on the <strong>${effectivePlanName}</strong> plan.`;
          }
        }
      }

      setExplanation(expl);
      setWarning(warning);

      //billingAddendum
      if (hasPendingSubscription) {
        if (userPlan.subscription.plan.plan_code !== userPlan.plan_code) {
          billingAddendumTxt = ` You have paid only for the <strong>${planNameWithCycle(
            userPlan.subscription.plan.plan_code
          )}</strong> level for the current billing cycle, so there will be a change to the <strong>${planNameWithCycle(
            userPlan.subscription.pending_subscription.plan.plan_code
          )}</strong> level on the next billing cycle.`;
        } else {
          billingAddendumTxt = ` And your plan and billing will switch to the <strong>${planNameWithCycle(
            userPlan.subscription.pending_subscription.plan.plan_code
          )}</strong> level on the next billing cycle.`;
        }
      } else if (canceledSubscription && userPlan.desired_plan_code === null && userPlan.plan_code !== null) {
        billingAddendumTxt = ` However, your cancelled <strong>${effectivePlanName}</strong> plan is still active until the end of the billing cycle. You will be billed a final time at the <strong>${planNameWithCycle(
          userPlan.subscription.plan.plan_code
        )}</strong> at end of this billing cycle.`;
      } else {
        billingAddendumTxt = '';
      }
      setBillingAddendum(billingAddendumTxt);
    }
  }, [userPlan]);

  return (
    <Card>
      <CardHeader>
        <h5>{t('subscription.play_time.title')}</h5>
      </CardHeader>
      <CardBody className="bg-light" style={{ minHeight: 300, minWidth: 280 }}>
        <div data-testid="subscription-explanation">
          <small>
            {explanation && (
              <div>
                <span dangerouslySetInnerHTML={{ __html: explanation }} />
                <span dangerouslySetInnerHTML={{ __html: billingAddendum }} />
              </div>
            )}
            {warning && <div dangerouslySetInnerHTML={{ __html: warning }} />}
          </small>
        </div>
        <div className="mt-4" data-testid="playtime">
          <small>
            {userPlan && userPlan.subscription_rules.remaining_month_play_time ? (
              <div>
                You have <strong>{displayTime(userPlan.subscription_rules.remaining_month_play_time)}</strong> remaining
                this month. Only the time you spend in a session with 2 or more people uses your session play time.
              </div>
            ) : (
              <div>You have unlimited play time.</div>
            )}
          </small>
        </div>
      </CardBody>
    </Card>
  );
}

export default JKSubscriptionPlaytime;
