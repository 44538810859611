import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import DashboardMain from '../components/dashboard/JKDashboardMain';
import UserAuth from '../context/UserAuth';
import { BrowserQueryProvider } from '../context/BrowserQuery';
import { NativeAppProvider } from '../context/NativeAppContext';
import { JKLobbyChatProvider } from '../components/sessions/JKLobbyChatContext';
import { AppRoutesProvider } from '../context/AppRoutesContext';
import { AppDataProvider } from '../context/AppDataContext';

const DashboardLayout = ({ location }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <UserAuth path={location.pathname}>
      <AppRoutesProvider>
        <AppDataProvider>
          <BrowserQueryProvider>
            <NativeAppProvider>
              <JKLobbyChatProvider>
                <DashboardMain />
              </JKLobbyChatProvider>
            </NativeAppProvider>
          </BrowserQueryProvider>
        </AppDataProvider>
      </AppRoutesProvider>
    </UserAuth>
  );
};

DashboardLayout.propTypes = { location: PropTypes.object.isRequired };

export default DashboardLayout;
