import React, { useState, useEffect, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { checkIsAuthenticated, authSignUp, authLogin, authLogout } from '../services/auth';

export const UserAuthContext = createContext({});

export const useAuth = () => useContext(UserAuthContext);

export default function UserAuth({ children, path }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    checkAuth();
  }, [path]);

  const checkAuth = () =>
    checkIsAuthenticated()
      .then(resp => resp.json())
      .then(user => {
        window.currentUser = user;
        setCurrentUser(user);
        setIsAuthenticated(true);
      })
      .catch(() => {
        setIsAuthenticated(false);
        setCurrentUser(null);
        window.currentUser = null;
      })
      .then(() => setIsLoading(false));

  const login = credentials =>
    authLogin(credentials)
      .then(setIsAuthenticated(true))
      .catch(error => {
        console.log(error);
        setIsAuthenticated(false);
      });

  const logout = () => {
    authLogout();
    setIsAuthenticated(false);
  };

  const signUp = credentials =>
    authSignUp(credentials)
      .then(setIsAuthenticated(true))
      .catch(error => {
        console.log(error);
        setIsAuthenticated(false);
      });

  return (
    <UserAuthContext.Provider
      value={{ currentUser, setCurrentUser, isAuthenticated, isLoading, login, logout, signUp }}
    >
      {children}
    </UserAuthContext.Provider>
  );
}

// UserAuth.propTypes = {
//   children: PropTypes.oneOfType([PropTypes.func, PropTypes.array])
// };
