import React from 'react'

function JKPrivacy() {
  return (
    <div>
      Privacy page
    </div>
  )
}

export default JKPrivacy
