import React, { useState } from 'react';
import JKProfileAvatar from '../profile/JKProfileAvatar';
import JKConnectButton from '../profile/JKConnectButton';
import JKMessageButton from '../profile/JKMessageButton';
import JKMoreDetailsButton from '../profile/JKMoreDetailsButton';
import JKLatencyBadge from '../profile/JKLatencyBadge';
import JKProfileInstrumentsList from '../profile/JKProfileInstrumentsList';
import JKProfileGenres from '../profile/JKProfileGenres';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import JKProfileSidePanel from '../profile/JKProfileSidePanel';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPerson } from '../../store/features/peopleSlice';
import { useResponsive } from '@farfetch/react-context-responsive';

import { useAuth } from '../../context/UserAuth';

function JKLobbyUser({ user, setSelectedUsers }) {
  const { t } = useTranslation();
  const [showSidePanel, setShowSidePanel] = useState(false);
  const { currentUser } = useAuth();
  const dispatch = useDispatch();
  const { greaterThan } = useResponsive();
  const latencyData = useSelector(state => state.latency.latencies.find(l => l.user_id === user.id));
  const userData = useSelector(state => state.people.people.find(p => p.id === user.id));

  const toggleMoreDetails = async e => {
    e.preventDefault();
    try {
      await dispatch(fetchPerson({ userId: user.id })).unwrap();
    } catch (error) {
      console.log(error);
    }
    setShowSidePanel(prev => !prev);
  };

  const setSelection = e => {
    if (e.target.checked) {
      setSelectedUsers(prev => [...prev, user.id]);
    } else {
      setSelectedUsers(prev => prev.filter(u => u !== user.id));
    }
  };

  return (
    <>
      {greaterThan.sm ? (
        <tr>
          <td className="align-middle">
            <div className="d-flex">
              <div className="mr-2 pt-2">
                <input type="checkbox" className="align-middle" onClick={setSelection} />
              </div>
              <div className="">
                <div className="d-flex align-items-center">
                  <div className="avatar avatar-sm mr-2">
                    <JKProfileAvatar src={user.photo_url} />
                  </div>
                  <div className="">
                    <a href="/#" onClick={toggleMoreDetails}>
                      <strong>{user.name}</strong>
                    </a>
                  </div>
                </div>
                <div>
                  <strong>{t('person_attributes.latency_to_me', { ns: 'people' })}:</strong>{' '}
                  <JKLatencyBadge latencyData={latencyData} />
                </div>
                <div>
                  <JKProfileInstrumentsList
                    instruments={user.instruments}
                    toggleMoreDetails={toggleMoreDetails}
                    showIcons={false}
                  />
                </div>
              </div>
            </div>
          </td>

          <td className="align-middle text-center">
            <div className="d-inline-flex flex-wrap" style={{ gap: '2px' }}>
              <JKConnectButton
                currentUser={currentUser}
                user={user}
                addContent={<FontAwesomeIcon icon="plus" transform="shrink-4 down-1" className="mr-1" />}
                removeContent={<FontAwesomeIcon icon="minus" transform="shrink-4 down-1" className="mr-1" />}
                cssClasses="fs--1 px-2 py-1 mr-1"
              />

              <JKMessageButton currentUser={currentUser} user={user} cssClasses="fs--1 px-2 py-1 mr-1">
                <FontAwesomeIcon icon="comments" transform="shrink-4 down-1" className="mr-1" />
              </JKMessageButton>

              <JKMoreDetailsButton toggleMoreDetails={toggleMoreDetails} cssClasses="btn btn-primary fs--1 px-2 py-1">
                <FontAwesomeIcon icon="user" transform="shrink-4 down-1" className="mr-1" />
              </JKMoreDetailsButton>
            </div>
          </td>
        </tr>
      ) : (
        <>
          <div>
            <strong>{t('person_attributes.latency_to_me', { ns: 'people' })}:</strong>{' '}
            <JKLatencyBadge latencyData={latencyData} />
          </div>
          <div className="mt-3">
            <h5>{t('person_attributes.instruments', { ns: 'people' })}</h5>
            <JKProfileInstrumentsList
              instruments={user.instruments}
              toggleMoreDetails={toggleMoreDetails}
              showIcons={false}
            />
          </div>
          <div className="mt-3">
            <h5>{t('person_attributes.genres', { ns: 'people' })}</h5>
            <JKProfileGenres genres={user.genres} toggleMoreDetails={toggleMoreDetails} />
          </div>
          <br />
          <div className="d-inline-flex flex-wrap" style={{ gap: '2px' }}>
            <JKConnectButton
              currentUser={currentUser}
              user={user}
              addContent={<FontAwesomeIcon icon="plus" transform="shrink-4 down-1" className="mr-1" />}
              removeContent={<FontAwesomeIcon icon="minus" transform="shrink-4 down-1" className="mr-1" />}
              cssClasses="fs--1 px-2 py-1 mr-1"
            />

            <JKMessageButton currentUser={currentUser} user={user} cssClasses="fs--1 px-2 py-1 mr-1">
              <FontAwesomeIcon icon="comments" transform="shrink-4 down-1" className="mr-1" />
            </JKMessageButton>

            <a href="/#" onClick={toggleMoreDetails} data-testid="btnMore">
              <span
                className="btn btn-primary fs--1 px-2 py-1"
                data-bs-toggle="tooltip"
                title={t('view_profile', { ns: 'people' })}
              >
                <FontAwesomeIcon icon="user" transform="shrink-4 down-1" className="mr-1" />
              </span>
            </a>
          </div>
        </>
      )}

      <JKProfileSidePanel user={userData} latencyData={latencyData} show={showSidePanel} setShow={setShowSidePanel} />
    </>
  );
}

export default JKLobbyUser;
