//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
import { useAuth } from '../../context/UserAuth';
import JKProfileAvatar from '../profile/JKProfileAvatar';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import useUserProfile from '../../hooks/useUserProfile';
import { useAppData } from '../../context/AppDataContext';

const ProfileDropdown = () => {
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const { isAuthenticated, currentUser, setCurrentUser, logout, currentUserProfile } = useAuth();
  const [cookies, setCookie, removeCookie] = useCookies(['remember_token']);
  const history = useHistory();

  // const { photoUrl } = useUserProfile(currentUser);
  const { appData } = useAppData();
  const { currentUserPhotoUrl } = appData;

  const handleLogout = async event => {
    event.preventDefault();
    removeCookie('remember_token', {
      domain: `.${process.env.REACT_APP_ORIGIN}`
    });
    setCurrentUser(null);
    await logout();
    history.push('/');
  };

  return (
    <>
      {isAuthenticated && (
        <Dropdown
          nav
          inNavbar
          data-testid="navbarTopProfileDropdown"
          isOpen={dropdownOpen}
          toggle={toggle}
          // onMouseOver={() => {
          //   let windowWidth = window.innerWidth;
          //   windowWidth > 992 && setDropdownOpen(true);
          // }}
          // onMouseLeave={() => {
          //   let windowWidth = window.innerWidth;
          //   windowWidth > 992 && setDropdownOpen(false);
          // }}
        >
          <DropdownToggle nav className="pr-0">
            {<JKProfileAvatar src={currentUserPhotoUrl} className="d-none d-lg-block d-xl-block" />}
            {/* <span className="d-none d-lg-block">{currentUser && currentUser.name}</span> */}
          </DropdownToggle>
          <DropdownMenu right className="dropdown-menu-card">
            <div className="bg-white rounded-soft py-2">
              {/* <DropdownItem tag={Link} to="/pages/settings">
              My Profile
            </DropdownItem> */}
              <DropdownItem onClick={handleLogout}>{t('signout', { ns: 'auth' })}</DropdownItem>
            </div>
          </DropdownMenu>
        </Dropdown>
      )}
    </>
  );
};

export default ProfileDropdown;
