import React from 'react';

function JKBuildMeta() {
  return (
    <div
      className="build-meta"
      dangerouslySetInnerHTML={{
        __html: `<!-- 
      BITBUCKET_BUILD_NUMBER: ${process.env.REACT_APP_BITBUCKET_BUILD_NUMBER} 
      BITBUCKET_COMMIT: ${process.env.REACT_APP_BITBUCKET_COMMIT}
      -->`
      }}
    />
  );
}

export default JKBuildMeta;
