import React, { useState } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import { useTranslation } from 'react-i18next';

import JKEditEmail from '../profile/JKEditEmail';
import JKEditPassword from '../profile/JKEditPassword';
import JKModalDialog from '../common/JKModalDialog';

const JKEditAccount = () => {
  const { t } = useTranslation('account');
  const [alertText, setAlertText] = useState("")

  const [showAlert, setShowAlert] = useState(false);
  const toggleAlert = () => setShowAlert(!showAlert);

  return (
    <>
    <Card>
      <FalconCardHeader title={t('identity.page_title')} titleClass="font-weight-bold" />
      <CardBody className="pt-3" style={{ backgroundColor: '#edf2f9' }}>
        <Row>
          <Col className='mb-2'>
            <JKEditEmail setAlert={setAlertText} toggleAlert={toggleAlert} />
          </Col>
          <Col>
            <JKEditPassword setAlert={setAlertText} toggleAlert={toggleAlert} />
          </Col>
          <Col></Col>
        </Row>
      </CardBody>
    </Card>
    <JKModalDialog
        show={showAlert}
        onToggle={toggleAlert}
        title={t('identity.modals.update_notification.title')}
        data-testid="native-app-unavailable"
        size="md"
      >
       {alertText} 
      </JKModalDialog>
    </>
  );
};

export default JKEditAccount;
