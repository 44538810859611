import { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPerson } from '../store/features/peopleSlice';

const useUserProfile = user => {
  const [userProfile, setUserProfile] = useState(null);
  const people = useSelector(state => state.people.people);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) {
      setUserProfile(null);
      return;
    }
    const person = people.find(person => person.id === user.id);
    if (person) {
      setUserProfile(person);
    } else {
      dispatch(fetchPerson({ userId: user.id }))
        .unwrap()
        .then(resp => {
          setUserProfile(resp);
        });
    }

    return () => {
      setUserProfile(null);
    };
  }, [user]);

  const photoUrl = useMemo(() => {
    if (userProfile && userProfile.v2_photo_uploaded) {
      return userProfile.v2_photo_url;
    } else if (userProfile && !userProfile.v2_photo_uploaded) {
      return user.photo_url;
    }
    return null;
  }, [userProfile]);

  return {
    userProfile,
    photoUrl
  };
};

export default useUserProfile;
