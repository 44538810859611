import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSessions, getPersonById } from '../../helpers/rest'

const initialState = {
  sessions: [],
  people: [],
  status: 'idel',
  error: null,
}

export const fetchSessions = createAsyncThunk(
  "session/fetchSessions", 
  async (options, thunkAPI) => {
    const response = await getSessions();
    return response.json();
  }

)

export const fetchPerson = createAsyncThunk(
  'session/fetchPerson', 
  async (options, thunkAPI) => {
    const {userId} = options
    const response = await getPersonById(userId)
    return response.json()
  }
  ,{
    condition: (options, {getState, extra}) => {
      const {session} = getState()
      const {userId} = options
      const person = session.people.find(person => person.id === userId)
      if(person && person.website){ 
        //only proceed if full data set for user has not been fetched. person.website is not included in the initial data fetching (i.e: in friends listing ).
        return false;
      }
    }
  }
)

export const SessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    addSession: (state) => {},
    updateSession: (state) => {},
    deleteSession: (state) => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSessions.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchSessions.fulfilled, (state, action) => {
        console.log(action.payload);
        state.status = "succeeded";
        state.sessions = action.payload;
      })
      .addCase(fetchSessions.rejected, (state, action) => {
        state.status = 'failed'
        state.error  = action.error.message
      })
      .addCase(fetchPerson.fulfilled, (state, action) => {
        const person = state.people.find(person => person.id === action.payload.id)
        if(person){
          const updated = {
            ...person, 
            ...action.payload
          }
          const objIndex = state.people.findIndex((p => p.id === updated.id));
          state.people[objIndex] = updated
        }else{
          state.people.push(action.payload)
        }
      })
  } 
})

export default SessionSlice.reducer;