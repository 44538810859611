import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import JKLatencyBadge from './JKLatencyBadge';

const JKUserLatency = ({user, showAll, showBadgeOnly}) => {
  const latencyData = useSelector(state => {
    const userLatency = state.latency.latencies.find(l => l.user_id === user.id);
    return {
      ars_internet_latency: userLatency?.ars?.internet_latency,
      audio_latency: userLatency?.audio_latency
    }
  });
  return (
    <JKLatencyBadge latencyData={latencyData} showAll={showAll} showBadgeOnly={showBadgeOnly} />
  );
};

JKUserLatency.propTypes = { user: PropTypes.object.isRequired };

export default JKUserLatency;
