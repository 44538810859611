import { useState, useEffect } from 'react';
import { fetchNotifications } from '../store/features/notificationSlice';
import { fetchPeopleByIds } from '../store/features/peopleSlice';
import { useDispatch, useSelector } from 'react-redux';

const useNotifications = user => {
  const LIMIT = 20;
  const [offset, setOffset] = useState(0);
  const [notifications, setNotifications] = useState([]);

  const reduxNotifications = useSelector(state => state.notification.notifications);
  const next = useSelector(state => state.notification.next);
  const notificationStatus = useSelector(state => state.notification.status);
  const unread_total = useSelector(state => state.notification.unread_total);
  const peopleStatus = useSelector(state => state.people.status);
  const people = useSelector(state => state.people.people);

  const dispatch = useDispatch();

  const loadNotifications = async () => {
    const options = {
      userId: user.id,
      offset: offset,
      limit: LIMIT
    };
    try {
      await dispatch(fetchNotifications(options)).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  const fetchNotificationSourceUsers = async () => {
    const sourceUserIds = reduxNotifications.map(notification => notification.source_user_id).filter((value, index, self) => self.indexOf(value) === index);
    const options = { userId: user.id, ids: sourceUserIds };
    try {
      await dispatch(fetchPeopleByIds(options)).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (reduxNotifications && reduxNotifications.length && notificationStatus === 'succeeded') {
      fetchNotificationSourceUsers();
    }
  }, [reduxNotifications, notificationStatus]);


  useEffect(() => {
    const newNotifications = reduxNotifications.filter(notification => !notifications.find(n => n.id === notification.id));
    setNotifications(prev => [...prev, ...newNotifications]);
  }, [people]);

  return {
    notifications,
    offset,
    setOffset,
    loadNotifications,
    next,
    unread_total,
    notificationStatus,
    peopleStatus
  };
};

export default useNotifications;
