import React from 'react';
import { Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import JKLobbyUser from './JKLobbyUser';
import { isIterableArray } from '../../helpers/utils';

function JKLobbyUserList({ onlineMusicians, selectedUsers, setSelectedUsers }) {
  const { t } = useTranslation('sessions');

  return (
    <>
      <Table striped bordered className="fs--1" data-testid="sessionsLobbyUsersTable">
        <thead className="bg-200 text-900">
          <tr>
            <th width="75%" scope="col">
              {t('lobby.users.header.musician', { ns: 'sessions' })}
            </th>
            <th scope="col" className="text-center">
              {t('lobby.users.header.actions', { ns: 'sessions' })}
            </th>
          </tr>
        </thead>
        <tbody className="list">
          {isIterableArray(onlineMusicians) ? (
            onlineMusicians.map(musician => (
              <JKLobbyUser key={musician.id} user={musician} setSelectedUsers={setSelectedUsers} />
            ))
          ) : (
            <tr>
              <td colSpan={2}>{t('lobby.users.not_found')}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}

export default JKLobbyUserList;
