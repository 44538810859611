import React from 'react';

const NativeAppContext = React.createContext(null)

export const NativeAppProvider = ({ children}) => {
  
  const [nativeAppUnavailable, setNativeAppUnavailable] = React.useState(false)

  return(
    <NativeAppContext.Provider value={ {nativeAppUnavailable, setNativeAppUnavailable} }>
      {children}
    </NativeAppContext.Provider>
  )
}

export const useNativeApp = () => React.useContext(NativeAppContext)