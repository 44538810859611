import { createSlice, createAsyncThunk, nanoid } from "@reduxjs/toolkit"
import { getTextMessages, createTextMessage } from '../../helpers/rest';

const initialState = {
  messages: [],
  status: 'idel',
  error: null
}

const resturectureTextMessage = (args) => {
  const { message, sent } = args
  const messageId = message.id ? message.id : nanoid()
  const createdAt = message.created_at ? message.created_at : new Date().toISOString()
  return {
    id: messageId,
    message: message.message,
    senderId: message.source_user_id,
    senderName: message.source_user['name'],
    receiverId: message.target_user_id,
    receiverName: message.target_user['name'],
    createdAt: createdAt,
    sent: sent
  }
}

export const fetchMessagesByReceiverId = createAsyncThunk(
  'textMessage/fetchMessagesByReceiverId', 
  async (options, thunkAPI) => {
    const { userId, offset, limit } = options
    const response = await getTextMessages({
      target_user_id: userId,
      offset: offset,
      limit: limit
    })
    return response.json()
  }
)

export const postNewMessage = createAsyncThunk(
  'textMessage/postNewMessage', 
  async (message, thunkAPI) => {
    const response = await createTextMessage(message)
    return response.json()
  }
)

export const textMessageSlice = createSlice({
  name: 'textMessage',
  initialState,
  reducers: {
    addMessage: (state, action) => {
      state.messages.push(action.payload)
    },
    updateMessage: state => {},
    deleteMessage: state => {}
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMessagesByReceiverId.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchMessagesByReceiverId.fulfilled, (state, action) => {
        state.status = 'succeeded'
        
        const msgs = action.payload.map(message => resturectureTextMessage({ message, sent: true }))
        const mergedMsgs = [...state.messages, ...msgs]
        const unique = [];
        mergedMsgs.map(x => unique.filter(a => a.id === x.id).length > 0 ? null : unique.push(x));
        state.messages = unique
      })
      .addCase(fetchMessagesByReceiverId.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(postNewMessage.fulfilled, (state, action) => {
        //console.log("postNewMessage fullfilled", action.payload);
        state.messages.push(resturectureTextMessage({ message: action.payload, sent: true }))
      })
      
  }
})

export const { addMessage, updateMessage, deleteMessage } = textMessageSlice.actions

export default textMessageSlice.reducer

//export const selectAllMessages = state => state.textMessage.messages

//export const selectMessageById = (state, messageId) => state.textMessage.messages.find(message => message.id === messageId)

//export const selectMessagesBySenderId = (state, senderId) => state.textMessage.messages.filter(message => message.senderId === senderId)
