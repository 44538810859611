import React, { useRef, useState, useEffect } from 'react';
import { Card, CardBody, Label, Form, FormGroup, Input, Row, Col } from 'reactstrap';
import Select from 'react-select';
import FalconCardHeader from '../common/FalconCardHeader';
import { useTranslation } from 'react-i18next';
import { Scrollbar } from 'react-scrollbars-custom';
import AgreementText from './AgreementText';
import { formatDate } from '../../helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createAffiliatePartner, getAffiliatePartnerData } from '../../helpers/rest';
import { toast } from 'react-toastify';
import { useAuth } from '../../context/UserAuth';
import { useResponsive } from '@farfetch/react-context-responsive';


const JKAffiliateAgreement = () => {
  const { t } = useTranslation('affiliate');
  const { greaterThan } = useResponsive();
  const { currentUser } = useAuth();
  const scrollbar = useRef();

  const [agreementData, setAgreementData] = useState(null);
  const [hasExecuted, setHasExecuted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitable, setSubmitable] = useState(false);

  const [agreementType, setAgreementType] = useState('');
  const [entityName, setEntityName] = useState('');
  const [entityType, setEntityType] = useState('');

  const [entityNameError, setEntityNameError] = useState('');
  const [entityTypeError, setEntityTypeError] = useState('');

  const ENTITY_TYPES = [
    {
      value: 'Sole Proprietor',
      label: 'Sole Proprietor'
    },
    {
      value: 'Limited Liability Company (LLC)',
      label: 'Limited Liability Company (LLC)'
    },
    {
      value: 'Partnership',
      label: 'Partnership'
    },
    {
      value: 'Trust State',
      label: 'Trust State'
    },
    {
      value: 'C Corporation',
      label: 'C Corporation'
    },
    {
      value: 'S Corporation',
      label: 'S Corporation'
    },
    {
      value: 'Other',
      label: 'Other'
    }
  ];

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    fetchAffiliate();
  }, [currentUser]);

  useEffect(() => {
    if (agreementType === 'Individual') {
      setSubmitable(true);
    }else if (agreementType === 'Entity') {
      if (entityName && entityType) {
        setSubmitable(true);
      } else {
        setSubmitable(false);
      }
    }
  }, [agreementType, entityName, entityType]);

  const fetchAffiliate = async () => {
    try{
      const response = await getAffiliatePartnerData(currentUser.id);
      const affiliate = await response.json();
      if (affiliate) {
        setAgreementData(affiliate.agreement);
        if (affiliate.agreement && affiliate.agreement.signed_at) {
          setHasExecuted(true);
        }
      }
    }catch(error){
      console.log(error);
    }
  }


  const handleSubmit = e => {
    e.preventDefault();
    
    let entityTypeVal = "Individual"
    if(agreementType !== 'Individual'){
      if(!isEntityValid()){
        setSubmitting(false);
        return;
      }
      entityTypeVal =  typeof entityType === 'object' ? entityType.value : entityType;
    }
    const params = {
      partner_name: entityName,
      entity_type: entityTypeVal
    };

    setSubmitting(true);
    createAffiliatePartner(params).then(resp => {
      console.log('resp', resp);
      if (resp.ok) {
        fetchAffiliate();
        toast.success('Agreement executed successfully');
      }
    }).catch(err => {
      console.log('err', err);
      toast.error('Error executing agreement');
    }).finally(() => {
      setSubmitting(false);
    });
  };

  const isEntityValid = () => {
    setEntityNameError('');
    setEntityTypeError('');
    let isValid = true;
    if (!entityName || entityName === '') {
      setEntityNameError('Entity name is required');
      isValid = false;
    }
    if (!entityType || entityType === '') {
      setEntityTypeError('Entity type is required');
      isValid = false;
    }
    return isValid;
  }

  return (
    <div className="mx-auto" style={{ width: greaterThan.sm?  '80%' : '100%' }}>
      <Card>
        <FalconCardHeader title={t('agreement.page_title')} titleClass="font-weight-semi-bold" />
        <CardBody className="pt-3 pb-5">
          {hasExecuted && (
            <div className="alert alert-info execution-date">
              ({t('agreement.executed_on')} {formatDate(agreementData.signed_at)})
            </div>
          )}
          <Scrollbar ref={scrollbar} style={{ width: '100%', height: 400 }} mobileNative={true}>
            <AgreementText />
          </Scrollbar>
        </CardBody>
      </Card>
      {!hasExecuted && (
        <>
          <div className="mt-3">
            <p>
              <strong>
               {t('agreement.agreement_note')} 
              </strong>
            </p>
          </div>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="agreement_individual" className='ml-3'>
                <Input
                  type="radio"
                  name="agreement"
                  id="agreement_individual"
                  value="Individual"
                  onClick={e => { 
                    setAgreementType(e.target.value); 
                    setEntityName(''); 
                    setEntityType('');
                    setEntityNameError('');
                    setEntityTypeError('');
                  }}
                />
                {t('agreement.form.individual')}
              </Label>
            </FormGroup>
            <FormGroup>
              <Label for="agreement_entity" className='ml-3'>
                <Input
                  type="radio"
                  name="agreement"
                  id="agreement_entity"
                  value="Entity"
                  onClick={e => setAgreementType(e.target.value)}
                />
                {t('agreement.form.entity')}
              </Label>
            </FormGroup>
            {agreementType === 'Entity' && (
              <>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="agreement_entity_name">{t('agreement.form.entity_name')}</Label>
                      <Input
                        type="text"
                        name="entity_name"
                        id="agreement_entity_name"
                        onChange={e => setEntityName(e.target.value)}
                      />
                      {entityNameError && <div className="text-danger"><small>{entityNameError}</small></div>}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="agreement_entity_type">{t('agreement.form.entity_type')}</Label>
                      <Select
                        options={ENTITY_TYPES}
                        name="entity_type"
                        id="agreement_entity_type"
                        onChange={setEntityType}
                      />
                      {entityTypeError && <div className="text-danger"><small>{entityTypeError}</small></div>}
                    </FormGroup>
                  </Col>
                </Row>
              </>
            )}

            <div className="d-flex align-content-center justify-content-start">
              <input
                type="submit"
                formNoValidate
                className="btn btn-primary"
                value={t('agreement.form.submit')}
                disabled={submitting || !submitable}
              />
              <span className="ml-2">{submitting && <FontAwesomeIcon icon="spinner" />}</span>
            </div>
          </Form>
        </>
      )}
    </div>
  );
};

export default JKAffiliateAgreement;
