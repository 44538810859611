import React, { useState, useEffect } from 'react';
import { Card, CardBody } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import { useTranslation } from 'react-i18next';
import JKJamTracksAutoComplete from '../jamtracks/JKJamTracksAutoComplete';
import { getJamTracks, getAffiliatePartnerData, autocompleteJamTracks } from '../../helpers/rest';
import { useAuth } from '../../context/UserAuth';
import { useHistory } from "react-router-dom";
import { useResponsive } from '@farfetch/react-context-responsive';

const JKAffiliateLinks = () => {
  const { t } = useTranslation('affiliate');
  const { greaterThan } = useResponsive();
  const { currentUser } = useAuth();
  const history = useHistory();
  const [affiliatePartnerId, setAffiliatePartnerId] = useState('xxxxx');
  const [affiliate, setAffiliate] = useState(null);
  const [jamTracks, setJamTracks] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [autoCompleteInputValue, setAutoCompleteInputValue] = useState('');
  const [jamTracksNextPage, setJamTracksNextPage] = useState(null);

  useEffect(() => {
    fetchAffiliate();
  }, []);

  const fetchAffiliate = async () => {
    try{
      const response = await getAffiliatePartnerData(currentUser.id);
      const affiliate = await response.json();
      if (affiliate) {
        setAffiliate(affiliate);
        setAffiliatePartnerId(affiliate.account.partner_id);
      }
    }catch(error){
      console.log(error);
    }
  }

  const fetchJamTracks = async options => {
   
    try {
      const resp = await getJamTracks(options);
      const data = await resp.json();
      console.log('data', data);
      setJamTracks(data.jamtracks);
      setJamTracksNextPage(data.next);
    } catch (error) {}
  };

  const queryOptions = selected => {
    const options = {
      limit: 100
    };

    if (typeof selected === 'string') {
      options.search = selected;
      return options;
    }

    if (selected.type === 'artist') {
      options.artist = selected.original_artist;
    } else {
      options.song = selected.name;
    }

    if (jamTracksNextPage !== null) {
      options.next = jamTracksNextPage;
    }
    return options;
  };

  const homePageLink = `https://www.jamkazam.com?affiliate=${affiliatePartnerId}`;
  const handleClickOnHomePageLink = () => {
    if(affiliate){
      window.open(homePageLink, '_blank');
    }else{
      history.push('/affiliate/agreement');
    }
  }

  const jamTrackLink = jamTrack => {
    return `https://jamkazam.com/jamtrack/landing/${jamTrack.plan_code}?affiliate=${affiliatePartnerId}`;
  }

  const handleOnClickJamTrackLink = () => {
    if(affiliate){
      window.open(jamTrackLink, '_blank');
    }else{
      history.push('/affiliate/agreement');
    }
  }

  //autocomplete related code
  const handleOnSelect = selected => {
    console.log('onSelect', selected);
    const params = queryOptions(selected);
    fetchJamTracks(params);
  };

  const handleOnEnter = queryStr => {
    console.log('onEnter', queryStr);
    const params = queryOptions(queryStr);
    fetchJamTracks(params);
  }

  return (
    <Card style={{ width: greaterThan.sm ? '75%' : '100%'}} className="mx-auto affiliate-links">
      <FalconCardHeader title={t('links.page_title')} titleClass="font-weight-semi-bold" />
      <CardBody className="pt-3 pb-5">
        <p>{t('links.paragraph1')}</p>
        <div className="affiliate-links__subtitle">{t('links.home_page_subtitle')}</div>
        <p>
          {t('links.home_page_paragraph')}:{' '}
          <a href="#" onClick={handleClickOnHomePageLink}>{homePageLink}</a>
        </p>
        <div className="affiliate-links__subtitle">{t('links.jamtracks_pages_subtitle')}</div>
        <p>{t('links.jamtracks_pages_paragraph')}</p>
        <div className='mt-4'>
          <JKJamTracksAutoComplete
              fetchFunc={autocompleteJamTracks}
              onSelect={handleOnSelect}
              onEnter={handleOnEnter}
              showDropdown={showDropdown}
              setShowDropdown={setShowDropdown}
              inputValue={autoCompleteInputValue}
              setInputValue={setAutoCompleteInputValue}
            />
        </div>
          {jamTracks &&
          jamTracks.map(jamTrack => {
            return (
              <div key={jamTrack.id}>
                <div>
                  {t('links.affiliate_link_for')} {jamTrack.name} {t('links.by')} {jamTrack.original_artist}:
                </div>
                <div>
                  <a href="#" onClick={handleOnClickJamTrackLink}>
                    {jamTrackLink(jamTrack)}
                  </a>
                </div>
              </div>
            );
          })}
      </CardBody>
    </Card>
  );
};

export default JKAffiliateLinks;
