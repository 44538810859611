import React from 'react'
import { Table, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import JKPayment from './JKPayment';

const JKPaymentHistoryList = ({payments, goToNextPage, loading}) => {
  const { t } = useTranslation('account');

  return (
    <>
    <Table striped bordered className="fs--1" data-testid="paymentsListTable">
    <thead className="bg-200 text-900">
      <tr>
        <th scope="col">{t('payments.payment_attributes.date')}</th>
        <th scope="col" style={{ minWidth: 250 }}>
          {t('payments.payment_attributes.description')}
        </th>
        <th scope="col">{t('payments.payment_attributes.status')}</th>
        <th scope="col">{t('payments.payment_attributes.amount')}</th>
      </tr>
    </thead>
    <tbody className="list">
      {payments.map(payment => (
        <JKPayment payment={payment} />
      ))}
    </tbody>
  </Table>
  <Button onClick={goToNextPage} data-testid="nextPageButton" disabled={loading}>
    {loading ? <span>{t('payments.loading')}</span> : <span>{t('payments.load_more')}</span> }
  </Button>
  </>
  )
}

export default JKPaymentHistoryList