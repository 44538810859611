import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Form, FormGroup, Label, Input, InputGroup, InputGroupAddon } from 'reactstrap';

import { useTranslation } from 'react-i18next';
import { useAuth } from '../../context/UserAuth';
import { useForm, Controller } from 'react-hook-form';
import { postUpdateAccountPassword, requestPasswordReset } from '../../helpers/rest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const JKEditPassword = ({ setAlert, toggleAlert }) => {
  const { t } = useTranslation('account');
  const { currentUser } = useAuth();
  const [user, setUser] = useState(null);

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const {
    handleSubmit: handleSubmit,
    control: control,
    formState: { errors },
    setError,
    setValue
  } = useForm({
    defaultValues: {
      current_password: '',
      new_password: ''
    }
  });

  useEffect(() => {
    if (currentUser) {
      setUser(currentUser);
    }
  }, [currentUser]);

  const onSubmitPassword = data => {
    setSubmitting(true);
    const { new_password, current_password } = data;
    postUpdateAccountPassword(currentUser.id, { current_password, new_password })
      .then(response => {
        setAlert(t('identity.password_form.alerts.updated'));
        setValue('current_password', '');
        setValue('new_password', '');
        toggleAlert();
      })
      .catch(async error => {
        console.log(error);
        const errorResp = await error.json();
        console.log(errorResp);
        if (errorResp.errors) {
          const errors = errorResp.errors;
          if (errors.current_password && errors.current_password.length) {
            errors.current_password.forEach(error => {
              setError('current_password', {
                type: 'manual',
                message: `${t('identity.password_form.current_password')} ${error}`
              });
            });
          }
          if (errors.password && errors.password.length) {
            errors.password.forEach(error => {
              setError('new_password', {
                type: 'manual',
                message: `${t('identity.password_form.new_password')} ${error}`
              });
            });
          }
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const requestResetPassword = async (e) => {
    e.preventDefault()
    if (!currentUser) {
      return;
    }
    try {
      await requestPasswordReset(currentUser.id);
      setAlert(t('identity.password_form.alerts.password_reset_email_sent'));
      toggleAlert();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Card>
      <CardHeader>
        <h5>Password</h5>
      </CardHeader>
      <CardBody className="bg-light" style={{ minHeight: 300, minWidth: 280 }}>
        <small>
          {t('identity.password_form.help_text')}
        </small>

        <Form className="mt-2" onSubmit={handleSubmit(onSubmitPassword)} data-testid="edit_password_form">
          <FormGroup>
            <Label for="current_password"> {t('identity.password_form.current_password')}</Label>
            <Controller
              name="current_password"
              control={control}
              rules={{ required: t('identity.password_form.validations.current_password.required') }}
              render={({ field }) => (
                <InputGroup>
                  <Input
                    {...field}
                    type={showPassword ? 'text' : 'password'}
                    className="form-control"
                    id="current_password"
                    placeholder={t('identity.password_form.current_password')}
                  />
                  <InputGroupAddon
                    addonType="append"
                    className="current-password-reveal"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  >
                    <span className="input-group-text">
                      <FontAwesomeIcon icon={showPassword ? 'eye-slash' : 'eye'} />
                    </span>
                  </InputGroupAddon>
                </InputGroup>
              )}
            />
            {errors.current_password && (
              <div className="text-danger">
                <small>{errors.current_password.message}</small>
              </div>
            )}
          </FormGroup>

          <FormGroup>
            <Label for="new_password">{t('identity.password_form.new_password')}</Label>
            <Controller
              name="new_password"
              control={control}
              rules={{ required: t('identity.password_form.validations.new_password.required') }}
              render={({ field }) => (
                <InputGroup>
                  <Input
                    {...field}
                    type={showNewPassword ? 'text' : 'password'}
                    className="form-control"
                    id="new_password"
                    placeholder="New Password"
                  />
                  <InputGroupAddon
                    addonType="append"
                    className='new-password-reveal'
                    onClick={() => {
                      setShowNewPassword(!showNewPassword);
                    }}
                  >
                    <span className="input-group-text">
                      <FontAwesomeIcon icon={showNewPassword ? 'eye-slash' : 'eye'} />
                    </span>
                  </InputGroupAddon>
                </InputGroup>
              )}
            />
            {errors.new_password && (
              <div className="text-danger">
                <small>{errors.new_password.message}</small>
              </div>
            )}
          </FormGroup>
          <div className="d-flex align-content-center justify-content-start">
            <input type="submit" className="btn btn-primary" value={t('identity.password_form.submit')} disabled={submitting} data-testid="password_submit" />
            <span className="ml-2">{submitting && <FontAwesomeIcon icon="spinner" />}</span>
          </div>
        </Form>
        <div className="mt-2">
          <small>
            {t('identity.password_form.forgot_password.help_text_p1')} <a href="#" data-testid="forgot_password" onClick={requestResetPassword}>{t('identity.password_form.forgot_password.click_here')}</a> {t('identity.password_form.forgot_password.help_text_p2')}
          </small>
        </div>
      </CardBody>
    </Card>
  );
};

export default JKEditPassword;
