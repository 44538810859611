import React from 'react';
import { Row, Col, Table, Button } from 'reactstrap';
import JKJamTrackPreview from './JKJamTrackPreview';
import JKJamTrackPurchaseButton from './JKJamTrackPurchaseButton';
import { JamTrackPreviewProvider } from '../../context/JamTrackPreviewContext';
import PropTypes from 'prop-types';

const JKJamTracksList = ({ selectedType, searchTerm, jamTracks, nextOffset, onNextPage }) => {
  return (
    <>
      {selectedType && searchTerm.length && jamTracks.length > 0 ? (
        <Row className="mb-2">
          <Col>
            <strong>
              Search Results: JamTracks for {selectedType} "{searchTerm}"
            </strong>
          </Col>
        </Row>
      ) : (
        searchTerm.length > 0 &&
        jamTracks.length > 0 && (
          <Row className="mb-2">
            <Col>
              <strong>Search Results: JamTracks including "{searchTerm}"</strong>
            </Col>
          </Row>
        )
      )}
      {jamTracks.length > 0 && (
        <Row>
          <Col>
            <Table striped bordered className="fs--1" data-testid="jamtracks-table">
              <thead className="bg-200 text-900">
                <tr>
                  <th width="30%">Song</th>
                  <th width="55%">Tracks</th>
                  <th>Shop</th>
                </tr>
              </thead>
              <tbody>
                <JamTrackPreviewProvider>
                  {jamTracks.map((jamTrack, index) => (
                    <tr key={`jamtrck-preview-row-${jamTrack.id}`}>
                      <td className='track-name-col'>
                        {jamTrack.name} by {jamTrack.original_artist}
                      </td>
                      <td className='track-tracks-col'>
                        <JKJamTrackPreview jamTrack={jamTrack} />
                      </td>
                      <td className='purchase-button-col'>
                        <JKJamTrackPurchaseButton jamTrack={jamTrack} />
                      </td>
                    </tr>
                  ))}
                </JamTrackPreviewProvider>
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
      {nextOffset && (
        <Row>
          <Col>
            <Button color="primary" onClick={onNextPage} data-testid="moreBtn">
              Load More
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

JKJamTracksList.propTypes = {
  selectedType: PropTypes.string || null,
  searchTerm: PropTypes.string,
  jamTracks: PropTypes.array,
  nextOffset: PropTypes.number,
  onNextPage: PropTypes.func,
};

JKJamTracksList.defaultProps = {
  selectedType: null,
  searchTerm: '',
  jamTracks: [],
  nextOffset: null,
  onNextPage: () => {}
};

export default JKJamTracksList;
