import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const JKModalDialog = (args) => {
  const { show, title, children, onToggle, showFooter, modalFooterButtons, ...rest } = args;
  const [modal, setModal] = useState(show);

  const toggle = () => {
    setModal(!modal);
    onToggle(!modal);
  };

  const { t } = useTranslation();

  useEffect(() => {
    setModal(show);
  }, [show]);

  return (
    <Modal isOpen={modal} toggle={toggle} {...rest}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      {showFooter && (
        <ModalFooter>
          {modalFooterButtons ? (
            <>
              {modalFooterButtons.map((button, index) => (
                <Button key={index} onClick={button.onClick} color={button.color} disabled={button.disabled}>
                  {button.text}
                </Button>
              ))}
            </>
          ) : (<Button onClick={toggle}>{t('close', { ns: 'common' })}</Button>)  }
         
        </ModalFooter>
      )}
    </Modal>
  );
};

JKModalDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onToggle: PropTypes.func.isRequired,
  showFooter: PropTypes.bool
};

JKModalDialog.defaultProps = {
  show: false,
  title: 'Modal Dialog',
  showFooter: true
};

export default JKModalDialog;
