import React, { useEffect, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Row, Col } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import { useTranslation } from 'react-i18next';
import JKAffiliatePayeeAddress from './JKAffiliatePayeeAddress';
import JKAffiliatePayeePaypal from './JKAffiliatePayeePaypal';
import JKAffiliatePayeeTax from './JKAffiliatePayeeTax';
import { useAuth } from '../../context/UserAuth';
import { getAffiliatePartnerData } from '../../helpers/rest';
import { useIsMounted } from '../../hooks/useIsMounted';
import { postAffiliatePartnerData } from '../../helpers/rest';
import { toast } from 'react-toastify';

const JKAffiliatePayee = () => {
  const { t } = useTranslation('affiliate');
  const { currentUser } = useAuth();
  const [affiliateUser, setAffiliateUser] = useState(null);
  const [notAffiliate, setNotAffiliate] = useState(false);
  const [loading, setLoading] = useState(false);
  const isMounted = useIsMounted();
  const [submitting, setSubmitting] = useState(false);

  const fetchAffiliatePartnerData = useCallback(async userId => {
    if (!userId) {
      return;
    }
    try {
      setLoading(true);
      const response = await getAffiliatePartnerData(userId);
      const affiliate = await response.json();
      setAffiliateUser(affiliate.account);
    } catch (error) {
      console.error('Error fetching affiliate partner data:', error);
      if (error && error.status === 400) {
        // If the affiliate partner data is not found, set the affiliate user to null
        setAffiliateUser(null);
        setNotAffiliate(true);
      }
    }finally {
      setLoading(false);
    }
  }, []);

  const onSubmit = data => {
    //post
    setSubmitting(true);
    const params = { ...affiliateUser, ...data};
    setAffiliateUser(params);
    postAffiliatePartnerData(currentUser.id, params).then(response => {
      if(response.ok) {
        // Show success message
        console.log('Affiliate partner data updated successfully');
        toast.success(t('payee.save_success'));
      }
     
    }).catch(error => {
      console.error('Error updating affiliate partner data:', error);
      toast.error(t('payee.save_error'));
      
    }).finally(() => {
      setSubmitting(false);
    });
  };

  useEffect(() => {
    // Fetch affiliate payee data
    if (currentUser) {
      fetchAffiliatePartnerData(currentUser.id);
    }
  }, [currentUser]);

  return (
    <Card>
      <FalconCardHeader title={t('payee.page_title')} titleClass="font-weight-bold" />
      <CardBody className="pt-3" style={{ backgroundColor: '#edf2f9' }}>
        {!isMounted || loading ? <p>Loading...</p> : notAffiliate ? (
          <Row>
            <Col>
              <p>{t('payee.not_affiliate')}</p>
              <Link to="/affiliate/program">Learn how you can earn cash simply by telling your friends and followers about JamKazam.</Link>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col className='mb-2 col-12 col-md-6 col-lg-4'>
              <JKAffiliatePayeeAddress affiliateUser={affiliateUser} onSubmit={onSubmit} submitting={submitting} />
            </Col>
            <Col>
                <JKAffiliatePayeePaypal affiliateUser={affiliateUser} onSubmit={onSubmit} submitting={submitting} />
                <div className='mb-2' />
                <JKAffiliatePayeeTax affiliateUser={affiliateUser} onSubmit={onSubmit} submitting={submitting} />
            </Col>
            <Col className='d-none d-lg-block' />
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default JKAffiliatePayee;
