import React, { useEffect } from 'react';
import { Card, CardBody, CardHeader, Form, FormGroup, Label, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const JKAffiliatePayeeTax = ({ affiliateUser, onSubmit, submitting }) => {
  const { t } = useTranslation('affiliate');
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue
  } = useForm({
    defaultValues: {
      tax_identifier: ''
    }
  });

  useEffect(() => {
    setValue('tax_identifier', affiliateUser?.tax_identifier || '');
  }, [affiliateUser]);

  const onSubmitTaxId = data => {
    const params = { "tax_identifier": data.tax_identifier };
    onSubmit(params);
  };

  return (
    <Card>
      <CardHeader>
        <h5>{t('payee.tax.title')}</h5>
      </CardHeader>
      <CardBody className="bg-light" style={{ minHeight: 200 }}>
        <small>{t('payee.tax.help_text')}</small>
        <Form noValidate className="mt-3" onSubmit={handleSubmit(onSubmitTaxId)}>
          <FormGroup>
            <Label for="tax_identifier">{t('payee.tax.form.tax_id')}</Label>
            <Controller
              name="tax_identifier"
              control={control}
              rules={{
                required: t('payee.tax.form.validations.tax_id.required')
              }}
              render={({ field }) => <Input {...field} type="text" className="form-control" id="tax_identifier" />}
            />
            {errors.tax_identifier && (
              <div className="text-danger">
                <small>{errors.tax_identifier.message}</small>
              </div>
            )}
          </FormGroup>
          <div className="d-flex align-content-center justify-content-end">
            <input
              type="submit"
              formNoValidate
              className="btn btn-primary"
              value={t('payee.tax.form.submit')}
              disabled={submitting}
              data-testid="tax_submit"
            />
            <span className="ml-2">{submitting && <FontAwesomeIcon icon="spinner" />}</span>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default JKAffiliatePayeeTax;
