import React from 'react';
import PropTypes from 'prop-types';
import defaultAvatarUrl from '../../assets/img/team/avatar.png';
import Avatar from '../common/Avatar';

const JKProfileAvatar = (options) => {
  const {src, size, ...rest} = options;
  const avatarUrl = () => {
    if (src) {
      return src;
    } else {
      return defaultAvatarUrl;
    }
  };

  return <Avatar src={avatarUrl()} size={size} rest />;
};

JKProfileAvatar.propTypes = {
  size: PropTypes.oneOf(['s', 'm', 'l', 'xl', '2xl', '3xl', '4xl', '5xl']).isRequired,
  rounded: PropTypes.string,
  src: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  name: PropTypes.string,
  emoji: PropTypes.string,
  className: PropTypes.string,
  mediaClass: PropTypes.string,
  isExact: PropTypes.bool
};

JKProfileAvatar.defaultProps = {
  size: 'l'
};

export default JKProfileAvatar;
